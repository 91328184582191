import { matchPath } from 'react-router-dom'
import { RouteParams, routes } from '@sketch/modules-common'
import {
  ProjectFragment,
  ProjectInSidebarAndHeaderFragment,
  ProjectDetailsFragment,
} from '@sketch/gql-types'

export const formatProjectName = (
  project: Pick<ProjectFragment, 'type' | 'name'>
) => {
  if (project.type === 'PERSONAL_DRAFTS') {
    return 'My Drafts'
  }

  return project.name
}

export const isViewingCurrentProject = (
  pathname: string,
  projectIdentifier: string
) => {
  const match = matchPath<RouteParams<'WORKSPACE_PROJECT'>>(pathname, {
    path: routes.WORKSPACE_PROJECT.template(),
    exact: true,
    strict: false,
  })

  return match?.params?.projectId === projectIdentifier
}

export const isViewingWorkspace = (
  pathname: string,
  workspaceIdentifier: string
) => {
  const match = matchPath<RouteParams<'WORKSPACE'>>(pathname, {
    path: routes.WORKSPACE.template(),
    strict: false,
  })

  return match?.params?.workspaceId === workspaceIdentifier
}

export const isMyDrafts = (
  project:
    | ProjectFragment
    | ProjectInSidebarAndHeaderFragment
    | ProjectDetailsFragment
) =>
  project.type === 'PERSONAL_DRAFTS' &&
  project.name === 'My Drafts' &&
  project.parentProjectIdentifier === null
