import styled, { css } from 'styled-components'
import { Text, Image, Modal, truncateStyles } from '@sketch/components'
import { ReactComponent as FolderOpenIcon } from '@sketch/icons/folder-open-16'

export const Header = styled(Modal.Header)(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.border.A};
  `
)

export const HeaderRight = styled.div`
  display: flex;
  margin: 0 24px;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
`

export const Title = styled(Text.Span).attrs({
  fontSize: 'G',
  fontWeight: 'regular',
})`
  line-height: 1.2;
  margin-bottom: 4px;
`

export const PreviewImage = styled(Image)(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    background-color: ${({ theme }) => theme.colors.background.tertiary.C};
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};

    img {
      height: auto;
      width: auto;
      max-width: 68px;
      max-height: 68px;
    }
  `
)

export const PreviewImageEmpty = styled.div(
  ({ theme }) => css`
    width: 68px;
    height: 68px;
    border-radius: ${theme.radii.large};
    border: 1px solid ${theme.colors.border.A};
    background-color: ${theme.colors.background.tertiary.D};
    position: relative;

    ::after {
      content: ' ';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 25px;
      right: 10px;
    }

    ::before {
      content: ' ';
      width: 28px;
      height: 28px;
      background-color: ${theme.colors.background.tertiary.B};
      position: absolute;
      top: 12px;
      left: 12px;
    }
  `
)

export const ShareParentName = styled.strong`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
`

export const separatorWidth = 16

export const SegmentSeparator = styled.span.attrs({ children: '/' })`
  min-width: ${separatorWidth}px;
  text-align: center;
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 8px;
`

export const TooltipSegment = styled.div<{ depth: number }>`
  padding-left: ${({ depth }) => depth * 8}px;
  display: flex;
  gap: 8px;
  align-items: center;
`
export const TooltipIcon = styled(FolderOpenIcon)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`

export const Subtitle = styled(Text.Span).attrs<{ bold?: boolean }>(props => ({
  textStyle: 'copy.tertiary.standard.D',
  fontWeight: props.bold ? 'medium' : 'normal',
}))`
  ${truncateStyles};
  max-width: 100%;
`
