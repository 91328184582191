/**
 * Color design tokens.
 *
 * Named colors defined in a plain object, we export one object per theme.
 *
 * For use with the following CSS properties:
 *
 *     color, background-color, border-color, caret-color, border-top-color,
 *     border-bottom-color, border-left-color, border-right-color,
 *     outline-color, fill, stroke
 *
 * @see https://system-ui.com/theme
 */

import prismColors from './generated/processedColors.json'
import { fallbackLightTheme, fallbackDarkTheme } from './fallbackThemes'

// Tint colors are only used for avatars placeholders now
export const tintColors: Record<string | number, string> = {
  default: 'hsl(19, 89%, 55%)',
  1: 'hsl(334, 100%, 38%)',
  2: 'hsl(28, 100%, 37.5%)',
  3: 'hsl(40, 89%, 44%)',
  4: 'hsl(90, 100%, 26%)',
  5: 'hsl(180, 100%, 25%)',
  6: 'hsl(199, 100%, 35%)',
  7: 'hsl(270, 70%, 50%)',
  8: 'hsl(300, 60%, 39%)',
}

// having fallback colour groups in case something goes wrong with the exporting
const fallbackSketch = {
  A: 'hsla(19, 89%, 55%, 1)',
  B: 'hsla(19, 89%, 55%, 0.85)',
  C: 'hsla(19, 89%, 55%, 0.7)',
  D: 'hsla(19, 89%, 55%, 0.55)',
  E: 'hsla(19, 89%, 55%, 0.3)',
  F: 'hsla(19, 89%, 55%, 0.1)',
} as const

const fallbackBrand = {
  beige: {
    default: {
      A: 'hsla(40, 52%, 75%, 1)',
      B: 'hsla(40, 52%, 75%, 0.85)',
      C: 'hsla(40, 52%, 75%, 0.85)',
      D: 'hsla(40, 52%, 75%, 0.55)',
      E: 'hsla(40, 52%, 75%, 0.3)',
      F: 'hsla(40, 52%, 75%, 0.1)',
    },
    foreground: 'hsla(40, 52%, 25%, 1)',
  },
  coral: {
    default: {
      A: 'hsla(22, 89%, 78%, 1)',
      B: 'hsla(22, 89%, 78%, 0.85)',
      C: 'hsla(22, 89%, 78%, 0.7)',
      D: 'hsla(22, 89%, 78%, 0.55)',
      E: 'hsla(22, 89%, 78%, 0.3)',
      F: 'hsla(22, 89%, 78%, 0.1)',
    },
    foreground: 'hsla(22, 68%, 28%, 1)',
  },
  green: {
    default: {
      A: 'hsla(78, 57%, 65%, 1)',
      B: 'hsla(78, 57%, 65%, 0.85)',
      C: 'hsla(78, 57%, 65%, 0.7)',
      D: 'hsla(78, 57%, 65%, 0.55)',
      E: 'hsla(78, 57%, 65%, 0.3)',
      F: 'hsla(78, 57%, 65%, 0.1)',
    },
    foreground: 'hsla(80, 100%, 18%, 1)',
  },
  purple: {
    default: {
      A: 'hsla(290, 38%, 67%, 1)',
      B: 'hsla(290, 38%, 67%, 0.85)',
      C: 'hsla(290, 38%, 67%, 0.7)',
      D: 'hsla(290, 38%, 67%, 0.55)',
      E: 'hsla(290, 38%, 67%, 0.3)',
      F: 'hsla(290, 38%, 67%, 0.1)',
    },
    foreground: 'hsla(290, 71%, 17%, 1)',
  },
  teal: {
    default: {
      A: 'hsla(174, 51%, 68%, 1)',
      B: 'hsla(174, 51%, 68%, 0.85)',
      C: 'hsla(174, 51%, 68%, 0.7)',
      D: 'hsla(174, 51%, 68%, 0.55)',
      E: 'hsla(174, 51%, 68%, 0.3)',
      F: 'hsla(174, 51%, 68%, 0.1)',
    },
    foreground: 'hsla(174, 91%, 18%, 1)',
  },
  yellow: {
    default: {
      A: 'hsla(44, 94%, 69%, 1)',
      B: 'hsla(44, 94%, 69%, 0.85)',
      C: 'hsla(44, 94%, 69%, 0.7)',
      D: 'hsla(44, 94%, 69%, 0.55)',
      E: 'hsla(44, 94%, 69%, 0.3)',
      F: 'hsla(44, 94%, 69%, 0.1)',
    },
    foreground: 'hsla(40, 100%, 24%, 1)',
  },
} as const

const fallbackInspect = {
  grid: {
    A: 'hsla(1, 99%, 55%, 0.6)',
    B: 'hsla(1, 99%, 55%, 0.3)',
    C: 'hsla(1, 99%, 55%, 0.1)',
  },
  guide: 'hsla(207, 100%, 51%, 1)',
  selection: 'hsla(1, 99%, 55%, 1)',
  symbol: {
    A: 'hsla(287, 76%, 50%, 1)',
    B: 'hsla(287, 76%, 50%, 0.2)',
  },
} as const

// Color tokens that are common for any theme
const common = {
  sketch: prismColors.sketch ?? fallbackSketch,
  brand: prismColors.brand ?? fallbackBrand,
  inspect: prismColors.inspect ?? fallbackInspect,
} as const

/**
 * Design tokens for Prism design system, separated from the original
 * ones so we can easily remove unused tokens in the future.
 */

// check if we have the prism colours properly generated
const colorsToUse = prismColors ?? fallbackLightTheme
const darkColorsToUse = prismColors.dark ?? fallbackDarkTheme

// items that should look the same on light and dark mode
const unchangedBetweenThemes = {
  tooltips: {
    title: colorsToUse.foreground.primary.A,
    foreground: colorsToUse.foreground.primary.B,
    background: darkColorsToUse.background.secondary.A,
    shortcutText: colorsToUse.foreground.primary.B,
    header: darkColorsToUse.foreground.secondary.D,
    shadow: colorsToUse.shadow.outer,
    separator: darkColorsToUse.border.A,
  },
  annotations: {
    commentListBackground: colorsToUse.background.tertiary.D,
    dotBackground: colorsToUse.foreground.primary.A,
    dotBackgroundRead: colorsToUse.foreground.secondary.C,
    dropShadowInner: colorsToUse.foreground.primary.A,
    dropShadowOuter: colorsToUse.shadow.outer,
  },
  modals: {
    colorfulModalsForeground: colorsToUse.foreground.primary.A,
  },
  buttons: {
    foreground: colorsToUse.foreground.primary.A,
    foregroundHover: colorsToUse.foreground.primary.B,
    foregroundActive: colorsToUse.foreground.primary.B,
    foregroundDisabled: colorsToUse.foreground.primary.B,
  },
  // This is a special case as we can't have opacity if we want to use it in both light and dark themes
  // Reference: https://www.sketch.com/s/7af23535-3dce-4251-a6b0-5f87aed1fa52/v/RE5k3l/a/1VLVDEa
  links: {
    negative: 'hsl(0, 100%, 56%)',
  },
  badges: {
    foreground: colorsToUse.foreground.primary.A,
  },
}

export const light = {
  _name: 'light',
  colors: {
    ...common,
    background: colorsToUse.background,
    foreground: colorsToUse.foreground,
    border: colorsToUse.border,
    shadow: colorsToUse.shadow,
    overlay: colorsToUse.overlay ?? fallbackLightTheme.overlay,
    state:
      {
        ...colorsToUse.state,
        // the warning colour does not exist on the design file, adding it
        warning: 'hsl(40, 89%, 44%)',
      } ?? fallbackLightTheme.state,
    skeleton: {
      baseColor: '#F0F0F0',
      highlightColor: '#FAFAFA',
    },
    ui: unchangedBetweenThemes,
    /**
     * We need to do fine tuning in some cases
     * as the conversion from light to dark is not always 1:1
     * For these scenarios we need to manually adjust the colours
     */
    buttons: {
      primaryUntinted: {
        color: colorsToUse.foreground.primary.A,
        backgroundColor: colorsToUse.background.primary.B,
        hover: {
          color: colorsToUse.foreground.primary.B,
          backgroundColor: colorsToUse.background.primary.A,
        },
        active: {
          color: colorsToUse.foreground.primary.B,
          backgroundColor: colorsToUse.background.primary.A,
        },
      },
      secondary: {
        color: colorsToUse.sketch.A,
        backgroundColor: colorsToUse.background.secondary.A,
        border: colorsToUse.border.B,
        hover: {
          color: colorsToUse.sketch.A,
          backgroundColor: colorsToUse.background.secondary.C,
        },
        active: {
          color: colorsToUse.sketch.A,
          backgroundColor: colorsToUse.background.secondary.C,
        },
      },
      secondaryUntinted: {
        color: colorsToUse.foreground.secondary.C,
        backgroundColor: colorsToUse.background.secondary.A,
        border: colorsToUse.border.B,
        hover: {
          color: colorsToUse.foreground.secondary.A,
          backgroundColor: colorsToUse.background.secondary.C,
        },
        active: {
          color: colorsToUse.foreground.secondary.A,
          backgroundColor: colorsToUse.background.secondary.C,
        },
      },
      secondaryNegative: {
        color: colorsToUse.state.negative.A,
      },
      pageSelector: {
        default: 'hsl(0, 0%, 98%)',
        hover: 'hsl(0, 0%, 94%)',
        active: 'hsl(0, 0%, 88%)',
      },
    },
    /**
     * Important note: don't use any HSL color in these stripe style objects,
     * Stripe doesn't support it, an error is thrown and inputs are unusable.
     *
     * This is the documentation about style object where it's not clear that they
     * don't accept HSL: https://stripe.com/docs/js/appendix/style?type=card
     */
    stripe: {
      foreground: 'rgba(0, 0, 0, 1)',
      invalid: 'rgba(204, 0, 0, 0.55)',
    },
    syntaxHighlighting: {
      background: 'rgb(250, 250, 250)',
    },
    headerSticky: {
      background: 'hsla(0, 0%, 100%, 0.9)',
      shadow: '0 5px 10px rgba(0, 0, 0, 0.02)',
    },
    thumbnails: {
      shadow: '0 2px 6px rgba(0 0 0 / 6%)',
      shadowHover: '0 2px 6px rgba(0 0 0 / 6%), 0 8px 14px rgba(0 0 0 / 10%)',
      shadowIcons: 'rgba(0 0 0 / 4%)',
    },
  },
}

/**
 * Dark theme,based on tint.default
 */
export const dark = {
  _name: 'dark',
  colors: {
    ...common,
    background: darkColorsToUse.background,
    foreground: darkColorsToUse.foreground,
    border: darkColorsToUse.border,
    shadow: darkColorsToUse.shadow,
    overlay: darkColorsToUse.overlay ?? fallbackDarkTheme.overlay,
    state:
      {
        // needed the fallbackDarkTheme because of the negative, neutral and so on state variables
        ...fallbackDarkTheme.state,
        ...darkColorsToUse.state,
      } ?? fallbackDarkTheme.state,
    skeleton: {
      baseColor: 'hsl(0, 0%, 27%)',
      highlightColor: 'hsl(240, 2%, 18%)',
    },
    ui: unchangedBetweenThemes,
    /**
     * We need to do fine tuning in some cases
     * as the conversion from light to dark is not always 1:1
     * For these scenarios we need to manually adjust the colours
     */
    buttons: {
      primaryUntinted: {
        color: colorsToUse.foreground.primary.A,
        backgroundColor: colorsToUse.background.primary.B,
        hover: {
          color: colorsToUse.foreground.primary.B,
          backgroundColor: colorsToUse.background.primary.A,
        },
        active: {
          color: colorsToUse.foreground.primary.B,
          backgroundColor: colorsToUse.background.primary.A,
        },
      },
      secondary: {
        color: darkColorsToUse.foreground.secondary.B,
        backgroundColor: darkColorsToUse.background.secondary.A,
        border: darkColorsToUse.border.B,
        hover: {
          color: darkColorsToUse.foreground.secondary.B,
          backgroundColor: darkColorsToUse.background.secondary.C,
        },
        active: {
          color: darkColorsToUse.foreground.secondary.B,
          backgroundColor: darkColorsToUse.background.secondary.C,
        },
      },
      secondaryUntinted: {
        color: darkColorsToUse.foreground.secondary.B,
        backgroundColor: 'transparent',
        border: darkColorsToUse.border.B,
        hover: {
          color: darkColorsToUse.foreground.secondary.A,
          backgroundColor: darkColorsToUse.background.secondary.C,
        },
        active: {
          color: darkColorsToUse.foreground.secondary.A,
          backgroundColor: darkColorsToUse.background.secondary.C,
        },
      },
      secondaryNegative: {
        color: unchangedBetweenThemes.links.negative,
      },
      pageSelector: {
        default: 'hsl(240%,2%,18%)',
        hover: 'hsl(240%,2%,24%)',
        active: 'hsl(240%,2%,24%)',
      },
    },
    /**
     * Important note: don't use any HSL color in these stripe style objects,
     * Stripe doesn't support it, an error is thrown and inputs are unusable.
     *
     * This is the documentation about style object where it's not clear that they
     * don't accept HSL: https://stripe.com/docs/js/appendix/style?type=card
     */
    stripe: {
      foreground: 'rgba(255, 255, 255, 1)',
      invalid: 'rgba(204, 0, 0, 0.55)',
    },
    syntaxHighlighting: {
      background: 'hsla(0, 0%, 0%, 0.85)',
    },
    headerSticky: {
      background: 'hsla(0, 0%, 0%, 0.8)',
      shadow: '0 5px 10px rgba(0, 0, 0, 0.02)',
    },
    thumbnails: {
      shadow: '0 2px 6px rgba(0 0 0 / 6%)',
      shadowHover: '0 2px 6px rgba(0 0 0 / 6%), 0 8px 14px rgba(0 0 0 / 10%)',
      shadowIcons: 'rgba(0 0 0 / 4%)',
    },
  },
}
