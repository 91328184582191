import React, { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { useModalContext } from '@sketch/components'
import { useToast } from '@sketch/toasts'
import { ChooseTargetWorkspaceDuplicationModal } from '../../modals/ChooseTargetWorkspaceDuplication'
import { ShareWithoutVersion } from '../../../versioning/ShareVersionContext/ShareVersionContext'
import { getEligibleWorkspacesForDuplication } from '../../../workspace/utils/getEligibleWorkspacesForDuplication/getEligibleWorkspacesForDuplication'
import { useCheckCurrentAuthorizationForWorkspace } from '../../../sso/operations/useCheckCurrentAuthorizationForWorkspace'
import { routes, isDraftsRoute } from '@sketch/modules-common'

import {
  DuplicationWorkspaceFragment,
  useDuplicateShareToDraftsMutation,
  useGetWorkspacesForDuplicationLazyQuery,
} from '@sketch/gql-types'
import { useEventDispatch } from '@sketch/utils'

interface UseDuplicateShareToDraftsProps {
  share: Pick<ShareWithoutVersion, 'name' | 'identifier' | 'publicAccessLevel'>
  workspaceIdentifier: string
  versionIdentifier: string
}

/**
 * useDuplicateShareToDrafts
 * Hook that handles Duplicate Share to My Drafts flow.
 *
 * What does the hook do?
 * When calling the returned function, the hook will assess whether
 * the modal to show the target workspace needs to be presented or not,
 * and will call duplicateShare mutation with the proper parameters.
 */
export function useDuplicateShareToDrafts(
  props: UseDuplicateShareToDraftsProps
) {
  const { share, versionIdentifier, workspaceIdentifier } = props
  const { showToast } = useToast()
  const { showModal, hideModal } = useModalContext()
  const [loading, setLoading] = useState(false)

  const { checkAuthorization } = useCheckCurrentAuthorizationForWorkspace()
  const dispatchShareRefresh = useEventDispatch('workspaceShareRefresh')

  /**
   *  We check whether the application is showing the
   *  drafts section here instead of prop drilling a
   *  property which can affect a lot of components until
   *  it gets here
   */
  const route = useRouteMatch()
  const isInDraftsSection = isDraftsRoute(route.path)

  const onDuplicationError = () => {
    showToast(
      'Unable to duplicate document. Please try again later.',
      'negative'
    )
    setLoading(false)
  }

  const onDuplicationSuccess = (
    targetWorkspaceId: string,
    targetWorkspaceName: string
  ) => {
    if (isInDraftsSection) {
      showToast('Document duplicated')
      setLoading(false)
      return
    }

    const link = routes.WORKSPACE_DRAFTS.create({
      workspaceId: targetWorkspaceId,
    })

    const duplicatedIntoDifferentWorkspace =
      targetWorkspaceId !== workspaceIdentifier

    showToast(
      <>
        Document duplicated to{' '}
        <Link to={link}>
          <b>My Drafts</b>
        </Link>
        {duplicatedIntoDifferentWorkspace ? ` in ${targetWorkspaceName}` : ''}
      </>
    )

    setLoading(false)
  }

  const onDuplicationCancel = () => {
    setLoading(false)
    hideModal()
  }

  const [
    getWorkspaces,
    getWorkspacesMutationStatus,
  ] = useGetWorkspacesForDuplicationLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { me } = data
      triggerShareDuplication(me.workspaces)
    },
    onError: onDuplicationError,
  })

  const [
    duplicateShareToDrafts,
    duplicateMutationStatus,
  ] = useDuplicateShareToDraftsMutation({
    onCompleted(data) {
      if (!data.duplicateShareToDrafts?.share) {
        return
      }

      dispatchShareRefresh({
        workspaceIdentifier: workspaceIdentifier,
        projectIdentifier:
          data.duplicateShareToDrafts.share.project?.identifier,
        collectionIdentifier:
          data.duplicateShareToDrafts?.share.collection?.identifier,
      })

      const { workspace } = data.duplicateShareToDrafts.share
      onDuplicationSuccess(workspace.identifier, workspace.name)
    },
    onError: onDuplicationError,
  })

  const triggerShareDuplication = (
    workspaces: DuplicationWorkspaceFragment[]
  ) => {
    const {
      currentWorkspace,
      otherWorkspaces,
    } = getEligibleWorkspacesForDuplication(
      workspaces,
      workspaceIdentifier,
      share.publicAccessLevel,
      { hasAccessToWorkspaceCheckFn: checkAuthorization }
    )

    if (otherWorkspaces.length) {
      showModal(ChooseTargetWorkspaceDuplicationModal, {
        share,
        versionIdentifier,
        workspaces: otherWorkspaces,
        onDuplicationSuccess,
        onDuplicationError,
        onDuplicationCancel,
      })
      return
    }

    if (currentWorkspace) {
      duplicateShareToDrafts({
        variables: {
          input: {
            sourceShareIdentifier: share.identifier,
            sourceVersionIdentifier: versionIdentifier,
            targetWorkspaceIdentifier: currentWorkspace.identifier,
          },
        },
      })
    }
  }

  const duplicateShare = useCallback(() => {
    setLoading(true)
    getWorkspaces()
  }, [getWorkspaces])

  return [
    duplicateShare,
    {
      ...duplicateMutationStatus,
      error: getWorkspacesMutationStatus.error || duplicateMutationStatus.error,
      loading,
    },
  ] as const
}
