import { PRMarinaRect } from '@sketch-hq/sketch-web-renderer'
import { useMemo } from 'react'
import { Line } from './types'
import { SketchElement, isTopLevelFrameElement } from '../../../../inspector'

type MeasurementData = {
  verticalLine?: Line
  horizontalLine?: Line
  secondHorizontalLine?: Line
  secondVerticalLine?: Line
}

/**
 * Get the coordinate and sizes of the lines to draw between the two provided elements.
 * We can draw up to 4 lines, two for each axis.
 * The size of each line is the distance in Sketch pixels, so we can use it to display
 * the distance value. This also means that we need to convert it to CSS pixels
 * before drawing the lines on top of the canvas.
 */
export function useMeasurementData(
  selectedElement: SketchElement | null,
  hoveredElement: SketchElement | null
) {
  const measurementData = useMemo((): MeasurementData | null => {
    const hoveredMarinaNode = hoveredElement?.prMarinaNode
    if (
      !selectedElement ||
      !hoveredMarinaNode ||
      // Don't show measurement lines when the selected element is an artboard
      isTopLevelFrameElement(selectedElement)
    ) {
      return null
    }

    const hoveredElementBounds = hoveredMarinaNode.getAbsoluteLayerBounds()
    const selectedElementBounds = selectedElement.prMarinaNode.getAbsoluteLayerBounds()

    const {
      mainHorizontalLine,
      secondaryHorizontalLine,
    } = getHorizontalMeasurementLines(
      hoveredElementBounds,
      selectedElementBounds
    )

    const {
      mainVerticalLine,
      secondaryVerticalLine,
    } = getVerticalMeasurementLines(hoveredElementBounds, selectedElementBounds)

    return {
      horizontalLine: mainHorizontalLine,
      secondHorizontalLine: secondaryHorizontalLine,
      verticalLine: mainVerticalLine,
      secondVerticalLine: secondaryVerticalLine,
    }
  }, [hoveredElement?.prMarinaNode, selectedElement])

  return measurementData
}

/**
 * Get the potential horizontal lines to draw between the selected and hovered element.
 */
function getHorizontalMeasurementLines(
  hoveredElementBounds: PRMarinaRect,
  selectedElementBounds: PRMarinaRect
): {
  mainHorizontalLine: Line | undefined
  /**
   * Sometimes we show more than one line. For example, when one element is fully inside the other.
   */
  secondaryHorizontalLine: Line | undefined
} {
  // Check if the selected element is fully inside the hovered element on the Y axis
  const selectedElementIsInsideHoveredElement =
    hoveredElementBounds.y < selectedElementBounds.y &&
    hoveredElementBounds.y + hoveredElementBounds.height >
      selectedElementBounds.y + selectedElementBounds.height
  /**
   * The y of the horizontal line is the horizontal middle point of the hovered element,
   * except when the hovered element contains the selected element, we use the middle of the selected
   * element, otherwise it looks weird.
   */
  const horizontalLineY = selectedElementIsInsideHoveredElement
    ? selectedElementBounds.y + selectedElementBounds.height / 2
    : hoveredElementBounds.y + hoveredElementBounds.height / 2

  /**
   * What we call start rect or start element here is the rect that has the left edge
   * the most on the left on the x axis (lowest x value).
   * It can be the hovered element or the selected element depending on the scenario.
   * @see getHorizontalMeasurementLineBetweenTwoRects for more info.
   */
  const startRectIsHoveredRect =
    hoveredElementBounds.x <= selectedElementBounds.x

  const startElementBounds = startRectIsHoveredRect
    ? hoveredElementBounds
    : selectedElementBounds
  const endElementBounds = startRectIsHoveredRect
    ? selectedElementBounds
    : hoveredElementBounds

  const startRect = {
    left: startElementBounds.x,
    right: startElementBounds.x + startElementBounds.width,
  }
  const endRect = {
    left: endElementBounds.x,
    right: endElementBounds.x + endElementBounds.width,
  }
  return getHorizontalMeasurementLineBetweenTwoRects(
    startRect,
    endRect,
    horizontalLineY,
    startRectIsHoveredRect
  )
}

/**
 * Calculate the coordinate of the horizontal line(s) to draw between two rects.
 * There are 3 scenarios where we draw horizontal lines differently:
 *  - No overlap between rectangles, just one line from the startRect right edge to the endRect left edge.
 *  - Complete overlap, endRect has the left and right edges inside the startRect (horizontally contained).
 *    We draw two horizontal lines, one on each side of the endRect.
 *  - Partial overlap, endRect has the left edge inside the startRect but the right edge outside. We
 *    draw only one line, the side depends on if the start rect is the hovered rect or the selected rect.
 *
 * @note We use the concept of start rect and end rect instead of hovered and selected element
 * so we can abstract which of the two is more on the left when we calculate the coordinates,
 * we always reason from left to right (start rect to end rect).
 * There is still a scenario (partial overlap) where we need to know which of the two is the hovered element,
 * hence why we pass the startRectIsHoveredRect boolean.
 */
function getHorizontalMeasurementLineBetweenTwoRects(
  /**
   * The rect that has the left edge the most on the left on the x axis (lowest x value).
   */
  startRect: { left: number; right: number },
  /**
   * The rect that has the left edge the most on the right on the x axis (highest x value).
   */
  endRect: { left: number; right: number },
  /**
   * This function focuses on calculating the correct coordinate of the line on the x axis. The y
   * value will not change. (The y value is the vertical middle point of the hovered or selected element)
   */
  yValue: number,
  /**
   * Whether the start rect (the rectangle with the left edge the most of the left on the x axis)
   * is the hovered rect or selected rect. In most cases this does not change anything but in the
   * scenario of partially overlapping rectangles we need to know so we can draw the line on the correct side
   * of the rectangle.
   */
  startRectIsHoveredRect: boolean
): { mainHorizontalLine: Line; secondaryHorizontalLine: Line | undefined } {
  if (startRect.right < endRect.left) {
    /**
     * The start rect is outside the end rect
     * So we draw a line from the start rect to the left edge of the end rect element
     * +............+     +.............+
     * | start rect |<--->|   end rect  |
     * +............+     +.............+
     */
    return {
      mainHorizontalLine: {
        x: startRect.right,
        y: yValue,
        size: endRect.left - startRect.right,
      },
      secondaryHorizontalLine: undefined,
    }
  }

  if (startRect.right >= endRect.right) {
    /**
     * The start rect is fully inside the hovered rect (horizontally).
     * In this case, we draw a line on both sides of the end rect
     *
     *        +.............+
     *        |   end-rect  |
     *        |             |
     *   +....|.............|....+
     *   |<-> +.............+ <->|
     *   |       startRect       |
     *   +.......................+
     */
    return {
      mainHorizontalLine: {
        x: startRect.left,
        y: yValue,
        size: endRect.left - startRect.left,
      },
      secondaryHorizontalLine: {
        x: endRect.right,
        y: yValue,
        size: startRect.right - endRect.right,
      },
    }
  }

  /**
   * The two rect overlap partially.
   * +..............+..............+..............+
   * { start rect   [ overlap zone }    end rect  ]
   * {              [              }              ]
   * +..............+..............+..............+
   *
   * For some unknown product reasons, in this case, we draw a line only from one of the edge.
   * (Zeplin and Figma both draw a line from all edges which would make things simpler.
   *  Probably we should reconsider this decision in the future)
   */

  if (startRectIsHoveredRect) {
    /**
     * Show the line from the left edge of the start rect
     * to the left edge of the end rect.
     * +..............+..............+..............+
     * { hovered rect [ overlap zone } selected rect ]
     * {<------------>[              }               ]
     * +..............+..............+..............+
     */
    return {
      mainHorizontalLine: {
        x: startRect.left,
        y: yValue,
        size: endRect.left - startRect.left,
      },
      secondaryHorizontalLine: undefined,
    }
  } else {
    /**
     * Show the line from the right edge of the start rect
     * to the right edge of the end rect.
     * +..............+..............+..............+
     * { hovered rect [ overlap zone } selected rect ]
     * {              [              }<------------>]
     * +..............+..............+..............+
     */
    return {
      mainHorizontalLine: {
        x: startRect.right,
        y: yValue,
        size: endRect.right - startRect.right,
      },
      secondaryHorizontalLine: undefined,
    }
  }
}

/**
 * Get the potential vertical lines to draw between the selected and hovered element.
 */
function getVerticalMeasurementLines(
  hoveredElementBounds: PRMarinaRect,
  selectedElementBounds: PRMarinaRect
): {
  mainVerticalLine: Line | undefined
  /**
   * Sometimes we show more than one line. For example, when one element is fully inside the other.
   */
  secondaryVerticalLine: Line | undefined
} {
  // Check if the selected element is fully inside the hovered element on the Y axis
  const selectedElementIsInsideHoveredElement =
    hoveredElementBounds.x < selectedElementBounds.x &&
    hoveredElementBounds.x + hoveredElementBounds.width >
      selectedElementBounds.x + selectedElementBounds.width
  /**
   * The x of the vertical line is the horizontal middle point of the hovered element,
   * except when the hovered element is the artboard, we use the middle of the selected
   * element, otherwise it looks weird.
   */
  const verticalLineX = selectedElementIsInsideHoveredElement
    ? selectedElementBounds.x + selectedElementBounds.width / 2
    : hoveredElementBounds.x + hoveredElementBounds.width / 2

  /**
   * What we call start rect or start element here is the rect that has the left edge
   * the most on the left on the x axis (lowest x value).
   * It can be the hovered element or the selected element depending on the scenario.
   * @see getVerticalMeasurementLineBetweenTwoRects for more info.
   */
  const startRectIsHoveredRect =
    hoveredElementBounds.y <= selectedElementBounds.y

  const startElementBounds = startRectIsHoveredRect
    ? hoveredElementBounds
    : selectedElementBounds
  const endElementBounds = startRectIsHoveredRect
    ? selectedElementBounds
    : hoveredElementBounds

  const startRect = {
    top: startElementBounds.y,
    bottom: startElementBounds.y + startElementBounds.height,
  }
  const endRect = {
    top: endElementBounds.y,
    bottom: endElementBounds.y + endElementBounds.height,
  }

  return getVerticalMeasurementLineBetweenTwoRects(
    startRect,
    endRect,
    verticalLineX,
    startRectIsHoveredRect
  )
}

/**
 * Calculate the coordinate of the vertical line(s) to draw between two rects.
 * There are 3 scenarios where we draw vertical lines differently:
 *  - No overlap between rectangles, just one line from the startRect bottom edge to the endRect top edge.
 *  - Complete overlap, endRect has the top and bottom edges inside the startRect (vertically contained).
 *    We draw two vertical lines, one on each side of the endRect.
 *  - Partial overlap, endRect has the top edge inside the startRect but the bottom edge outside. We
 *    draw only one line, the side depends on if the start rect is the hovered rect or the selected rect.
 *
 * @note We use the concept of start rect and end rect instead of hovered and selected element
 * so we can abstract which of the two is more on the left when we calculate the coordinates,
 * we always reason from left to right (start rect to end rect).
 * There is still a scenario (partial overlap) where we need to know which of the two is the hovered element,
 * hence why we pass the startRectIsHoveredRect boolean.
 */
function getVerticalMeasurementLineBetweenTwoRects(
  /**
   * The rect that has the top edge the most on the top on the y axis (lowest y value).
   */
  startRect: { top: number; bottom: number },
  /**
   * The rect that has the top edge the most on the bottom on the y axis (highest y value).
   */
  endRect: { top: number; bottom: number },
  /**
   * This function focuses on calculating the correct coordinate of the line on the y axis. The x
   * value will not change. (The x value is the horizontal middle point of the hovered or selected element)
   */
  xValue: number,
  /**
   * Whether the start rect (the rectangle with the top edge the most of the top on the y axis)
   * is the hovered rect or selected rect. In most cases this does not change anything but in the
   * scenario of partially overlapping rectangles we need to know so we can draw the line on the correct side
   * of the rectangle.
   */
  startRectIsHoveredRect: boolean
): {
  mainVerticalLine: Line | undefined
  /**
   * Sometimes we show more than one line. For example, when one element is fully inside the other.
   */
  secondaryVerticalLine: Line | undefined
} {
  if (startRect.bottom < endRect.top) {
    /**
     * The start rect is outside the end rect
     * So we draw a line from the bottom edge of the start rect to the top edge of the end rect
     * +------------+
     * : start rect :
     * +------------+
     *       |
     * +------------+
     * : end rect :
     * +------------+
     */
    return {
      mainVerticalLine: {
        x: xValue,
        y: startRect.bottom,
        size: endRect.top - startRect.bottom,
      },
      secondaryVerticalLine: undefined,
    }
  }

  if (startRect.bottom >= endRect.bottom) {
    /**
     * The start rect is fully inside the hovered rect (vertically).
     * In this case, we draw a line on both sides of the selected element
     * +------------------+
     * : start   |  rect  :
     * :         |        :
     * :   +------------+ :
     * :   :  end rect  : :
     * :   :            : :
     * :   +------------+ :
     * :         |        :
     * +------------------+
     */
    return {
      mainVerticalLine: {
        x: xValue,
        y: startRect.top,
        size: endRect.top - startRect.top,
      },
      secondaryVerticalLine: {
        x: xValue,
        y: endRect.bottom,
        size: startRect.bottom - endRect.bottom,
      },
    }
  }

  /**
   * The two rect overlap partially.
   * +-----------------+
   * :  start rect     :
   * :                 :
   * +*****************+
   * :   overlap       :
   * :    zone         :
   * +-----------------+
   * :                 :
   * :   end rect      :
   * +*****************+
   *
   * For some unknown product reasons, in this case, we draw a line only from one of the edge.
   * (Zeplin and Figma both draw a line from all edges which would make things simpler.
   *  Probably we should reconsider this decision in the future)
   */

  if (startRectIsHoveredRect) {
    /**
     * Show the line from the top edge of the start rect
     * to the top edge of the end rect.
     *
     * +-----------------+
     * :  start | rect    :
     * :        |        :
     * +*****************+
     * :   overlap       :
     * :    zone         :
     * +-----------------+
     * :                 :
     * :   end rect      :
     * +*****************+
     *
     */

    return {
      mainVerticalLine: {
        x: xValue,
        y: startRect.top,
        size: endRect.top - startRect.top,
      },
      secondaryVerticalLine: undefined,
    }
  } else {
    /**
     * Show the line from the bottom edge of the start rect
     * to the top edge of the end rect.
     * +-----------------+
     * :  start   rect    :
     * :                 :
     * +*****************+
     * :   overlap       :
     * :    zone         :
     * +-----------------+
     * :       |         :
     * :   end |  rect   :
     * +*****************+
     */
    return {
      mainVerticalLine: {
        x: xValue,
        y: startRect.bottom,
        size: endRect.bottom - startRect.bottom,
      },
      secondaryVerticalLine: undefined,
    }
  }
}
