import React from 'react'

import { Flex, SegmentsMap, TruncateSegments } from '@sketch/components'
import {
  Header,
  Title,
  PreviewImage,
  PreviewImageEmpty,
  separatorWidth,
  SegmentSeparator,
  Subtitle,
  HeaderRight,
  TooltipContent,
  TooltipSegment,
  TooltipIcon,
} from './MoveDocumentModalHeader.styles'

import { ShareInfoFragment } from '@sketch/gql-types'

const ShareLocation: React.FC<{
  share?: Pick<ShareInfoFragment, 'project' | 'workspace'>
}> = ({ share }) => {
  if (!share) {
    return null
  }

  const directParentId = share.project?.identifier || share.workspace.identifier

  /**
   * Add all parents up until the workspace, that means:
   * - workspace
   * - parent projects
   * - project of the share
   *
   * we are going to use these segments for the breadcrumbs
   */
  const segments = [
    { id: share.workspace.identifier, name: share.workspace.name },

    ...(share.project?.parentProjects.map(p => ({
      id: p.identifier,
      name: p.name,
    })) || []),

    ...(share.project
      ? [{ id: share.project.identifier, name: share.project.name }]
      : []),
  ]

  const segmentsMap: SegmentsMap = new Map()
  for (const entry of segments) {
    segmentsMap.set(entry.id, {
      id: entry.id,
      name: entry.name,
      node: (
        <Subtitle bold={entry.id === directParentId}>{entry.name}</Subtitle>
      ),
    })
  }

  return (
    <Subtitle>
      <TruncateSegments
        segments={segmentsMap}
        separator={{ element: <SegmentSeparator />, width: separatorWidth }}
        ellipsis={{ element: <span>…</span>, width: separatorWidth }}
        renderTooltip={segmentIds => {
          return (
            <TooltipContent>
              {segmentIds.map((id, index) => (
                <TooltipSegment key={id} depth={index}>
                  <TooltipIcon />
                  {segmentsMap.get(id)?.name}
                </TooltipSegment>
              ))}
            </TooltipContent>
          )
        }}
      />
    </Subtitle>
  )
}

interface MoveDocumentModalHeaderProps {
  share?: Pick<ShareInfoFragment, 'version' | 'name' | 'project' | 'workspace'>
}

export const MoveDocumentModalHeader = (
  props: MoveDocumentModalHeaderProps
) => {
  const { share } = props
  const thumbnails = share?.version?.document?.previewFile?.thumbnails
  const image = thumbnails ? thumbnails[0]?.url : undefined

  return (
    <Header>
      <Flex data-testid="move-document-modal-header" alignItems="center" mb={4}>
        {image ? (
          <PreviewImage alt={share?.name ?? ''} src={image} />
        ) : (
          <PreviewImageEmpty data-testid="move-document-placeholder" />
        )}

        <HeaderRight>
          <Title>
            <strong>Move:</strong> {share?.name ?? <>&hellip;</>}
          </Title>
          <ShareLocation share={share} />
        </HeaderRight>
      </Flex>
    </Header>
  )
}
