import React from 'react'
import { useModalContext, Text } from '@sketch/components'
import { LinkButton, CheckIcon, Wrapper } from './LinkNonSSO.styles'
import { LinkNonSSOModal } from './LinkNonSSOModal'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
// Usage of WorkspaceMembership is needed for SSOWorkspace
// eslint-disable-next-line no-restricted-imports
import { WorkspaceMembership } from '@sketch/gql-types/expansive'

interface LinkNonSSOProps {
  linkedEmail?: string | null
  workspaceName: string
  currentWorkspace: WorkspaceMinimalFragment
  SSOWorkspace: WorkspaceMembership
}

export const LinkNonSSO = ({
  linkedEmail,
  workspaceName,
  currentWorkspace,
  SSOWorkspace,
}: LinkNonSSOProps) => {
  const { showModal } = useModalContext()

  const currentWSIsSSO =
    currentWorkspace?.type === 'STANDARD' && SSOWorkspace?.workspace

  if (linkedEmail) {
    return (
      <Wrapper>
        <CheckIcon />
        <Text textStyle="copy.primary.standard.C">
          {currentWSIsSSO ? (
            <>
              Linked to <strong>{linkedEmail}</strong>
            </>
          ) : (
            <>
              Linked to SSO <strong>{SSOWorkspace.workspace.name}</strong>
            </>
          )}
        </Text>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <LinkButton
        isUnderlined
        onClick={() => showModal(LinkNonSSOModal, { workspaceName })}
      >
        Link to Non-SSO Account...
      </LinkButton>
    </Wrapper>
  )
}
