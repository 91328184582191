import React, { useMemo } from 'react'

import { CanvasRendererProvider } from '@sketch-hq/sketch-web-renderer'
import {
  FrameGroupDetailsFragment,
  GetFrameViewQuery,
  useGetFrameViewQuery,
  VersionFragment,
} from '@sketch/gql-types'
import { RouteParams, useQueryRetry } from '@sketch/modules-common'
import { RouteComponentProps } from 'react-router-dom'

import { AnnotationQueryVariablesProvider } from '../../../../annotations/context'
import { DocumentSidebarLayoutExtraProps } from '../../../components/DocumentSidebarLayout'
import { Pagination } from '../../../types'
import {
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
  useVersioning,
} from '../../../../versioning'

import { FrameGroupContextProvider } from '../../../ArtboardDetailView/hooks/useFrameGroupContext'
import { FrameGroupDetailErrorBoundary } from '../../../ArtboardDetailView/components/FrameGroupDetailErrorBoundary'
import { FrameGroupInspectorProvider } from '../../../ArtboardDetailView/components/FrameGroupInspectorProvider/FrameGroupInspectorProvider'
import { FrameView } from '../FrameView'
import { ArtboardAnnotationsOverlayContext } from '../../../ArtboardDetailView/components/ArtboardAnnotationsOverlayContext'
import { INSPECTOR_SEGMENT } from '../../../constants'

// TODO: Add ArtboardAnnotationsOverlayContext
// https://linear.app/sketch/issue/SWEB-499/implement-annotations-within-frameview

// TODO: Add frame subscription
// https://linear.app/sketch/issue/SWEB-501/implement-subscriptions-for-frames

const getFrame = (result: { data?: GetFrameViewQuery }) => {
  const frame = result?.data?.frame
  // at some point Apollo (while loading) returns a frame which contains only
  // a symbol of id, all other properties are non existing
  // this gives a false impression, that the object is there, but it isn't
  return frame?.identifier ? frame : undefined
}

// TODO: Review this
// type MaybeFrame = ReturnType<typeof getFrame>

interface UseRetryGetFrameQueryProps {
  frameUUID: string
  versionShortId?: string
  shareIdentifier: string
  enabled: boolean
}

const useRetryGetFrameViewQuery = ({
  frameUUID,
  versionShortId,
  shareIdentifier,
  enabled = true,
}: UseRetryGetFrameQueryProps) =>
  useQueryRetry(
    useGetFrameViewQuery({
      shouldInvalidatePrevious: (prev, curr) =>
        prev?.frameUUID !== curr?.frameUUID,
      // To account for frame updates we're setting a cache-and-network
      // policy for frame queries without an explicit version.
      fetchPolicy: versionShortId ? 'cache-first' : 'cache-and-network',
      variables: {
        frameUUID: frameUUID,
        documentVersionShortId: versionShortId,
        shareIdentifier,
      },
      skip: !enabled,
    }),
    {
      retryIf: result => {
        if (!enabled) return false
        const frame = getFrame(result)
        const files = (frame && frame.files) || []
        return files.some(file => !file?.url)
      },
    }
  )

const getPagination = (
  version: VersionFragment | undefined,
  frame: FrameGroupDetailsFragment | undefined
): Pagination | undefined => {
  if (!version || !frame) return undefined

  // TODO: Should this artboardCount also be renamed on BE side?
  const total = version.document?.artboardCount
  const current = frame.documentOrder == null ? undefined : frame.documentOrder

  return { current, total }
}

// TODO: Add frame subscription
// https://linear.app/sketch/issue/SWEB-501/implement-subscriptions-for-frames
// const getArtboardForSubscription = (
//   maybeArtboard: MaybeFrame
// ): UseRevisionSubscriptionsProps['artboard'] | null => {
//   if (!maybeArtboard?.identifier || !maybeArtboard?.permanentArtboardShortId)
//     return null

//   return {
//     identifier: maybeArtboard.identifier,
//     permanentArtboardShortId: maybeArtboard.permanentArtboardShortId,
//     uuid: maybeArtboard.uuid,
//   }
// }

type FrameViewRouteProps = RouteComponentProps<Partial<RouteParams<'FRAME'>>>

export interface FrameQueriesProps
  extends FrameViewRouteProps,
    DocumentSidebarLayoutExtraProps {}

export const FrameQueries = ({
  match,
  history,
  location,
  ...layoutProps
}: FrameQueriesProps) => {
  const { frameUUID } = match.params

  const isInspectPanelOpen = location.hash.includes(INSPECTOR_SEGMENT)

  const versionContext = useVersioning()

  const { versionShortId, share, isViewingLatestVersion } = versionContext

  const frameAtDocResult = useRetryGetFrameViewQuery({
    frameUUID: frameUUID!,
    versionShortId,
    shareIdentifier: share.identifier,
    enabled: true,
  })

  const frameAtLatestResult = useRetryGetFrameViewQuery({
    frameUUID: frameUUID!,
    /*
     * passing latestVersionId does not work
     * as on the latest version of the document the frame might be deleted
     * leaving this comment here, so that if anyone would
     * try to optimise it in the future, they would be aware
     * more: https://github.com/sketch-hq/cloud-frontend/pull/800#discussion_r289356950
     */
    // versionId={latestVersionId}
    shareIdentifier: share.identifier,
    enabled: true,
  })

  const error = frameAtDocResult.error || frameAtLatestResult.error

  const loading = frameAtDocResult.loading || frameAtLatestResult.loading

  const frames = {
    docVersion: getFrame(frameAtDocResult),
    latest: getFrame(frameAtLatestResult),
  }

  const subjects = useMemo(() => {
    if (frames.docVersion && frames.docVersion.page?.uuid) {
      return [
        {
          // Will be soon migrated to "FRAME"
          type: 'ARTBOARD' as const,
          permanentId: frames.docVersion.uuid,
          permanentPageId: frames.docVersion.page.uuid,
        },
      ]
    }

    return []
  }, [frames.docVersion])

  if (isUpgradeToLatestNeeded(error)) {
    frameAtLatestResult.refetch()
    return <UpgradeToLatestVersion error={error} />
  }

  const { currentVersion } = (!versionContext.loading && versionContext) || {}

  const pagination = getPagination(currentVersion, frames.docVersion)
  const frameIdAtLatestVersion =
    frames.latest?.documentVersionShortId || undefined

  // const subscriptionFrame = getArtboardForSubscription(artboards.revVersion)

  const disableAnnotations = !frames.docVersion || !share.commentsEnabled

  return (
    <FrameGroupDetailErrorBoundary>
      <CanvasRendererProvider>
        <FrameGroupInspectorProvider
          //  Reset context for each frame
          key={frames.docVersion?.identifier}
        >
          <AnnotationQueryVariablesProvider
            shareIdentifier={share.identifier}
            subjects={subjects}
          >
            <ArtboardAnnotationsOverlayContext
              disabled={disableAnnotations}
              hidden={isInspectPanelOpen}
              permanentPageId={frames.docVersion?.page?.uuid || ''}
              isViewingLatestVersion={isViewingLatestVersion}
            >
              <FrameGroupContextProvider>
                <FrameView
                  share={share}
                  currentVersion={currentVersion}
                  frame={frames.docVersion!}
                  isViewingLatestVersion={isViewingLatestVersion}
                  isPrototypePlayEnabled={isViewingLatestVersion}
                  frameIdAtLatestVersion={frameIdAtLatestVersion}
                  pagination={pagination}
                  error={error}
                  loading={loading}
                  layoutProps={layoutProps}
                />

                {/*
              // TODO: Add frame subscription
              // https://linear.app/sketch/issue/SWEB-501/implement-subscriptions-for-frames
              {subscriptionArtboard && (
                <RevisionSubscriptions
                  artboard={subscriptionArtboard}
                  onRevisionChange={onRevisionChange}
                  share={share}
                />
              )} 
             */}
              </FrameGroupContextProvider>
            </ArtboardAnnotationsOverlayContext>
          </AnnotationQueryVariablesProvider>
        </FrameGroupInspectorProvider>
      </CanvasRendererProvider>
    </FrameGroupDetailErrorBoundary>
  )
}
