import {
  // TODO: Move routes to @sketch/modules-common, https://github.com/sketch-hq/Cloud/issues/16596
  getAllTemplatesForVersionedRoute,
  routes,
  versionedRoutes,
} from '../routes'

/**
 * CONSTANTS
 */
export const CWV_ROUTES = [
  ...getAllTemplatesForVersionedRoute('CWV_SYMBOLS'),
  ...getAllTemplatesForVersionedRoute('CWV_TEXT_STYLES'),
  ...getAllTemplatesForVersionedRoute('CWV_LAYER_STYLES'),
  ...getAllTemplatesForVersionedRoute('CWV_COLOR_VARIABLES'),
]

/**
 * HELPERS
 *
 * To idenfity routes using react router `path`. Usage:
 * ```
 * const Foo = () => {
 *   const { path } = useRouteMatch()
 *   ...
 *   return isDocumentRoute(path) ? ... : ...;
 * }
 * ```
 */
export const isAllDocumentsRoute = (path: string) =>
  routes.ALL_DOCUMENTS.template() === path

export const isWorkspaceRoute = (path: string) =>
  routes.WORKSPACE_SHARES.template() === path

export const isDocumentRoute = (path: string) =>
  versionedRoutes.SHARE_VIEW.LATEST.template() === path ||
  versionedRoutes.SHARE_VIEW.VERSION.template() === path

export const isDocumentPageRouteLegacy = (path: string) =>
  versionedRoutes.SHARE_PAGE_VIEW.LATEST.template() === path ||
  versionedRoutes.SHARE_PAGE_VIEW.VERSION.template() === path

export const isDocumentPageRouteCanvas = (path: string) =>
  versionedRoutes.SHARE_PAGE_CANVAS_VIEW.LATEST.template() === path ||
  versionedRoutes.SHARE_PAGE_CANVAS_VIEW.VERSION.template() === path

export const isDocumentPageRoute = (path: string) =>
  isDocumentPageRouteLegacy(path) || isDocumentPageRouteCanvas(path)

export const isDocumentPrototypeRoute = (path: string) =>
  versionedRoutes.SHARE_PROTOTYPES.LATEST.template() === path ||
  versionedRoutes.SHARE_PROTOTYPES.VERSION.template() === path

export const isArtboardRoute = (path: string) =>
  versionedRoutes.ARTBOARD_DETAIL.LATEST.template() === path ||
  versionedRoutes.ARTBOARD_DETAIL.VERSION.template() === path ||
  versionedRoutes.ARTBOARD_REVISION.LATEST.template() === path ||
  versionedRoutes.ARTBOARD_REVISION.VERSION.template() === path

export const isFrameGroupRoute = (path: string) =>
  versionedRoutes.FRAME.LATEST.template() === path ||
  versionedRoutes.FRAME.VERSION.template() === path

export const isTrashRoute = (path: string) =>
  routes.WORKSPACE_TRASH.template() === path

export const isArchiveRoute = (path: string) =>
  routes.WORKSPACE_ARCHIVE.template() === path

export const isProjectTrashRoute = (path: string) =>
  routes.WORKSPACE_TRASH_PROJECT.template() === path

export const isDraftsRoute = (path: string) =>
  routes.WORKSPACE_DRAFTS.template() === path

export const isCwvRoute = (path: string): boolean =>
  !!CWV_ROUTES.find(route => route === path) ||
  isDocumentRoute(path) ||
  isDocumentPageRoute(path) ||
  isDocumentPrototypeRoute(path)

export const isCwvRouteOnly = (path: string) =>
  !!CWV_ROUTES.find(route => route === path)

export const isCwvSpecificRoute = (
  path: string,
  route:
    | 'SHARE_VIEW'
    | 'SHARE_PAGE_VIEW'
    | 'CWV_SYMBOLS'
    | 'CWV_TEXT_STYLES'
    | 'CWV_LAYER_STYLES'
    | 'CWV_COLOR_VARIABLES'
) => !!getAllTemplatesForVersionedRoute(route).find(r => r === path)

export const getWhichCwvRoute = (path: string): string => {
  enum links {
    SHARE_VIEW = 'SHARE_VIEW',
    CWV_SYMBOLS = 'CWV_SYMBOLS',
    CWV_TEXT_STYLES = 'CWV_TEXT_STYLES',
    CWV_LAYER_STYLES = 'CWV_LAYER_STYLES',
    CWV_COLOR_VARIABLES = 'CWV_COLOR_VARIABLES',
  }

  if (isCwvSpecificRoute(path, links.SHARE_VIEW)) {
    return links.SHARE_VIEW
  }
  if (isCwvSpecificRoute(path, links.CWV_SYMBOLS)) {
    return links.CWV_SYMBOLS
  }
  if (isCwvSpecificRoute(path, links.CWV_LAYER_STYLES)) {
    return links.CWV_LAYER_STYLES
  }
  if (isCwvSpecificRoute(path, links.CWV_TEXT_STYLES)) {
    return links.CWV_TEXT_STYLES
  }

  return links.CWV_COLOR_VARIABLES
}

export const isDesignSystemRoute = (path: string) =>
  routes.DESIGN_SYSTEM.template() === path

export const isLibrariesRoute = (path: string) =>
  routes.WORKSPACE_LIBRARIES.template() === path

export const isSharedWithMeRoute = (path: string) =>
  routes.WORKSPACE_SHARED_WITH_ME.template() === path

export const isTemplatesRoute = (path: string) =>
  routes.WORKSPACE_TEMPLATES.template() === path

export const isProjectRoute = (path: string) =>
  routes.WORKSPACE_PROJECT.template() === path

export const isCollectionRoute = (path: string) =>
  routes.WORKSPACE_COLLECTION.template() === path

export const isInspectorRoute = (path: string, hash?: string) =>
  (isDocumentRoute(path) || isArtboardRoute(path)) && hash === '#Inspect'

export const isWorkspaceSettingsRoute = (path: string) =>
  routes.WORKSPACE_SETTINGS.template() === path ||
  routes.WORKSPACE_SETTINGS_SETTINGS.template() === path ||
  routes.WORKSPACE_SETTINGS_PEOPLE.template() === path ||
  routes.WORKSPACE_SETTINGS_BILLING.template() === path ||
  routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.template() === path ||
  routes.WORKSPACE_SETTINGS_PROFILE.template() === path ||
  routes.WORKSPACE_SETTINGS_SSO.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template() === path ||
  routes.WORKSPACE_AGNOSTIC_SETTINGS_SSO.template() === path

export const isPersonalSettingsRoute = (path: string) =>
  routes.PERSONAL_SETTINGS.template() === path ||
  routes.PERSONAL_SETTINGS_ACCESS_TOKENS.template() === path ||
  routes.PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE.template() === path ||
  routes.PERSONAL_SETTINGS_DISABLE_MFA.template() === path ||
  routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template() === path ||
  routes.APPEARANCE_SETTINGS.template() === path

export const isDiscoverRoute = (path: string) =>
  routes.WORKSPACE_DISCOVER.template() === path

export const isUpdatesRoute = (path: string) =>
  routes.UPDATES.template() === path || routes.UPDATES_READ.template() === path
