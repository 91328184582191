import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useRouteMatch, useLocation } from 'react-router'

import AboutTab from '../AboutTab'
import {
  ArtboardAnnotations,
  ShareAnnotations,
} from '../../../annotations/containers'
import {
  ShareVersions,
  ArtboardRevisions,
} from '../../../versioning/components'
import { ShareWithoutVersion } from '../../../versioning/ShareVersionContext/ShareVersionContext'
import InspectorRestricted from '../../ComponentsWebView/components/InspectorRestricted'
import { useInspectContext } from '../../ComponentsWebView/context/InspectContext'
import { useShareSidebarTab, getActivePanels } from '../ShareSidebarTabContext'
import { PanelWrapper, FakeButton, MobileWrapper } from './Panel.styles'
import { useResponsiveDropdown, useForTablet } from '@sketch/components'
import { isArtboardRoute } from '@sketch/modules-common'
import { usePanelShortcuts } from './hooks'

// This is a valid use case to narrow down Component type
// eslint-disable-next-line no-restricted-imports
import { NotificationSubscriptionStatus } from '@sketch/gql-types/expansive'

import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
  VersionFragment,
} from '@sketch/gql-types'

// This import needs to be specific to prevent circular-dependencies
import { ArtboardDetailInspector } from '../../ArtboardDetailView/components/ArtboardDetailInspector'
import { useHideAnnotationDots } from '../../../annotations/hooks'

const ChildrenRender: React.FC = ({ children }) => (
  <PanelWrapper data-testid="panel-component">{children}</PanelWrapper>
)

const DROPDOWN_MODIFIERS = [
  {
    name: 'preventOverflow' as const,
    options: { boundary: 'clippingParents', padding: 8 },
  },
]

interface PanelProps {
  share: ShareWithoutVersion
  version?: VersionFragment
  frameGroup?: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  latestFrameSubscriptionStatus?: NotificationSubscriptionStatus
  userCanSeeComments: boolean
  userCanInspect?: boolean
  SidebarRightPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
}

/**
 * This component renders the right floating panel that contains info that
 * before we showed in tabs in the right sidebar, like document info, version
 * list, comments or the inspector.
 */
export const Panel: React.FC<PanelProps> = ({
  userCanInspect,
  userCanSeeComments,
  share,
  version,
  frameGroup,
  SidebarRightPortal,
  latestFrameSubscriptionStatus,
}) => {
  /**
   * Enables this shortcuts:
   * - `d` to toggle the document info panel
   * - `i` to toggle the inspector panel
   * - `c` to toggle the comments panel
   * - `v` to toggle the versions panel
   */
  usePanelShortcuts()

  // Used to keep track of the active segment to prevent a transition glitch
  // when the panel disappears, that happens when the panel content is hide
  // before the transition ends
  const lastActiveSegment = useRef<string | null>(null)

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { path } = useRouteMatch()
  const isArtboardPath = isArtboardRoute(path)
  const location = useLocation()

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: userCanInspect,
        canComment: userCanSeeComments,
      }),
    [path, userCanInspect, userCanSeeComments]
  )

  const [segments, setSegments] = useState(activePanels)

  // Save the panels the user has access to
  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, setActiveSegment } = useShareSidebarTab(segments)
  const { selectedItem, handleSelectItem } = useInspectContext()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHideAnnotations] = useHideAnnotationDots() || []

  // if we're linking to a document, land on the comment mode
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const annotation = searchParams.get('annotation')

    if (annotation) {
      setActiveSegment('Comment')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Enable the "Inspector" panel when the "selectItem" is set
  useEffect(() => {
    if (selectedItem) {
      setActiveSegment('Inspect')
    }
  }, [selectedItem, setActiveSegment])

  // When the panel is changed the inspected item should be unselected
  useEffect(() => {
    if (activeSegment === 'Inspect') {
      return () => {
        handleSelectItem(null)
      }
    }
  }, [activeSegment, handleSelectItem])

  // When the comments panel is active, we force the comments dots to be visible
  useEffect(() => {
    if (activeSegment === 'Comment') {
      setHideAnnotations?.(false)
    }
  }, [activeSegment, setHideAnnotations])

  // Render the selected tab
  let tab = null

  // Cache the last active segment to prevent a visual bug when closing the panel
  const getActiveSegment = () => activeSegment || lastActiveSegment.current

  switch (getActiveSegment()) {
    case 'About': {
      lastActiveSegment.current = 'About'
      tab = <AboutTab shareIdentifier={share.identifier} />
      break
    }
    case 'Comment': {
      lastActiveSegment.current = 'Comment'
      tab = isArtboardPath ? (
        frameGroup && (
          <ArtboardAnnotations
            share={share}
            showAddAnnotationButton={!isMobile}
            permanentPageIdentifier={frameGroup.page!.identifier}
            frameGroup={frameGroup}
            artboardNotificationStatus={latestFrameSubscriptionStatus || 'OFF'}
          />
        )
      ) : (
        <ShareAnnotations share={share} showAddAnnotationButton={!isMobile} />
      )
      break
    }
    case 'Version': {
      lastActiveSegment.current = 'Version'
      tab = isArtboardPath ? (
        frameGroup &&
        frameGroup.__typename === 'Artboard' && (
          <ArtboardRevisions
            shareIdentifier={share.identifier}
            permanentArtboardIdentifier={frameGroup.uuid}
            permanentArtboardShortId={frameGroup.permanentArtboardShortId}
            artboardRevisionIdentifier={frameGroup.revisionIdentifier}
          />
        )
      ) : (
        <ShareVersions shareIdentifier={share.identifier} />
      )
      break
    }
    case 'Inspect': {
      lastActiveSegment.current = 'Inspect'
      tab = userCanInspect ? (
        <ArtboardDetailInspector share={share} currentVersion={version} />
      ) : (
        <InspectorRestricted />
      )
      break
    }
  }

  const [
    dropdown,
    button,
    { visible, setVisible, update },
  ] = useResponsiveDropdown({
    dropdown: ChildrenRender,
    dropdownProps: { children: tab },
    offset: [8, 8],
    placement: 'bottom-end',
    usePortal: false,
    dropdownStyle: {
      /**
       * This is currently a work-around for limiting the dropdown content
       * to the browser full height, popper should do this automatically but is failing
       * so.
       */
      maxHeight: 'calc(100vh - 66px)',
    },
    modifiers: DROPDOWN_MODIFIERS,
    hide: [],
  })

  // Sync activeSegment with dropdown visibility
  useEffect(() => {
    if (!!activeSegment !== visible) {
      update?.()
      setVisible(state => !state)
    }
  }, [activeSegment, visible, setVisible, update])

  return isMobile ? (
    <SidebarRightPortal>
      <MobileWrapper>{tab}</MobileWrapper>
    </SidebarRightPortal>
  ) : (
    <>
      {/* Not visible because we toggle the visibility programatically, but is
      needed in order to make the dropdown work */}
      <FakeButton {...button} />

      {dropdown}
    </>
  )
}
