import {
  LayerStyle,
  TextLayerStyleProperties,
  LayerStyleWithSharedStyle,
  ElementType,
  SketchNonGraphicLayerElement,
  SketchGroupLayerElement,
  SketchSymbolInstanceLayerElement,
} from '../types'
import {
  SketchFrameElement,
  SketchElement,
  SketchLayerElement,
  SketchPageElement,
} from '../types/SketchScene'

export function isFrameElement(
  element: SketchElement
): element is SketchFrameElement {
  return (
    element.type === ElementType.SymbolMaster ||
    element.type === ElementType.Artboard
  )
}

export function isTopLevelFrameElement(
  element: SketchElement
): element is SketchFrameElement {
  return isFrameElement(element) && !element.artboardAncestorElement
}

export const findFrameElementFromSelected = (
  selectedElement: SketchElement
): SketchElement | null => {
  if (isTopLevelFrameElement(selectedElement)) {
    return selectedElement
  }

  if (selectedElement.artboardAncestorElement) {
    return findFrameElementFromSelected(selectedElement.artboardAncestorElement)
  }

  return null
}

// Finds the frame element from these 2 scenarios:
// - When an element is selected
// - When there is an frame in the frame detail view
export const findFrameElement = (
  selectedElement: SketchElement | null,
  sketchSceneRootElement: SketchElement | null
) => {
  return selectedElement
    ? findFrameElementFromSelected(selectedElement)
    : sketchSceneRootElement && isTopLevelFrameElement(sketchSceneRootElement)
    ? sketchSceneRootElement
    : null
}

export function isPageElement(
  element: SketchElement
): element is SketchPageElement {
  return element.type === ElementType.Page
}

export function isLayerElement(
  element: SketchElement
): element is SketchLayerElement {
  return !isPageElement(element) && !isTopLevelFrameElement(element)
}

export function isSymbolMasterElement(element: SketchElement) {
  return element.type === ElementType.SymbolMaster
}

export function isArtboardElementType(type: ElementType) {
  return type === ElementType.Artboard || type === ElementType.SymbolMaster
}

export function isGroupElement(
  element: SketchElement
): element is SketchGroupLayerElement | SketchSymbolInstanceLayerElement {
  return isGroupElementType(element.type)
}

export function isGroupElementType(type: ElementType) {
  return type === 'group' || type === 'symbolInstance'
}

export function isLayerWithSharedStyle(
  style: LayerStyle | undefined
): style is LayerStyleWithSharedStyle {
  return style ? 'isDirty' in style : false
}

export function isTextLayerStyle(
  style: LayerStyle
): style is TextLayerStyleProperties {
  return 'text' in style
}

export function isNonGraphicLayerElement(
  element: SketchElement
): element is SketchNonGraphicLayerElement {
  return isNonGraphicElementType(element.type)
}

export function isNonGraphicElementType(type: SketchElement['type']) {
  return type === ElementType.Hotspot || type === ElementType.Slice
}

export function getFramesWithGridsAndLayouts(
  element: SketchElement,
  prev: SketchFrameElement[] = []
): SketchFrameElement[] {
  if (isFrameElement(element) && (element.gridSettings || element.layerSize)) {
    prev.push(element)
  }

  return element.children.reduce(
    (acc, element) => getFramesWithGridsAndLayouts(element, acc),
    prev
  )
}
