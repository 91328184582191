import React, { useContext } from 'react'

import { Dropdown, ModalContext } from '@sketch/components'
import { RenameShareModal } from '@sketch/modules-common'

interface RenameActionProps {
  id: string
  name: string
  workspaceId: string
}

const RenameAction: React.FC<RenameActionProps> = ({
  id,
  name,
  workspaceId,
  children,
}) => {
  const { showModal } = useContext(ModalContext)

  return (
    <Dropdown.Item
      onClick={() => {
        showModal(RenameShareModal, {
          id,
          name,
          workspaceId,
        })
      }}
    >
      {children}
    </Dropdown.Item>
  )
}

export default RenameAction
