import config from '@sketch/env-config'
import { AwsConfig } from '../cryptoWorker/client'
import { STSClient, AssumeRoleWithSAMLCommand } from '@aws-sdk/client-sts'

export const fetchAwsConfig = async (
  authToken: string,
  sessionId: string
): Promise<AwsConfig> => {
  const response = await fetch(
    `${config.graphql_api_domain}/v1/saml-assertion/${sessionId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${authToken}`,
      },
    }
  )
  const json = await response.json()
  const client = new STSClient({ region: json.data.aws.region })
  const command = new AssumeRoleWithSAMLCommand({
    RoleArn: json.data.aws.role_arn,
    PrincipalArn: json.data.aws.principal_arn,
    DurationSeconds: json.data.aws.session_duration_secs,
    SAMLAssertion: json.data.saml_assertion,
  })
  const awsResponse = await client.send(command)
  return {
    kmsArn: json.data.aws.kms_arn,
    awsRegion: json.data.aws.region,
    credentials: {
      accessKeyId: awsResponse.Credentials!.AccessKeyId!,
      secretAccessKey: awsResponse.Credentials!.SecretAccessKey!,
      sessionToken: awsResponse.Credentials!.SessionToken!,
    },
  }
}
