import { dataIdFromObject } from '@sketch/graphql-cache'

import { isTruthy, useOnEvent } from '@sketch/utils'
import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'

import { handleFetchMore } from '@sketch/components'

import { useGetProjectCollections } from '../../../collections/hooks'
import { useProjectBanner } from '../useProjectBanner'
import { useGetProjectShares } from './useGetProjectShares'

import { ShareSearchFilter } from '../../../shares/hooks/useSearchFilters'
import { useGetNestedProjects } from './useGetNestedProjects'
import { useGetProjects } from '../../operations'
import { ProjectLink, BreadcrumbsData } from '../../types'
import { useGetWorkspaceQuery } from '@sketch/gql-types'
import { ApolloError } from 'apollo-client'

interface UseProjectsProps {
  workspaceId: string
  projectId: string
  search?: string
  isNestedProjectsOn?: boolean
  filters?: ShareSearchFilter[]
}

const sharesEntriesPath = ['project', 'shares', 'entries']
const collectionsEntriesPath = ['project', 'collections', 'entries']
const nestedProjectsEntriesPath = ['project', 'projects', 'entries']

const getParentProjects = (
  projectsById: { [x: string]: ProjectLink },
  currentProjectId: string
) => {
  const projectIds: string[] = []

  let parentId = projectsById[currentProjectId]?.project.parentProjectIdentifier
  while (parentId) {
    projectIds.push(parentId)
    parentId = projectsById[parentId]?.project.parentProjectIdentifier
  }

  const parentProjects = projectIds
    .map(id => projectsById[id]?.project)
    .filter(isTruthy)
  return parentProjects
}

const useProjectsBreadcrumbsData = (
  workspaceId: string,
  currentProjectId: string
) => {
  // we are fetching all projects and using just a small part of it
  // because we know that query should be already cached
  // if this ever changes we should refactor this to use a new query.
  const {
    networkStatus: projectsNetworkStatus,
    error: projectsError,
    allProjects,
    projectsById,
  } = useGetProjects({ workspaceId })

  const {
    data,
    networkStatus: workspaceNetworksStatus,
    error: workspaceError,
  } = useGetWorkspaceQuery({ variables: { identifier: workspaceId } })

  const currentProject = projectsById[currentProjectId]?.project
  const parentProjectId = currentProject?.parentProjectIdentifier || null

  const parentProject =
    (parentProjectId && projectsById[parentProjectId]?.project) || null

  const siblingProjects =
    allProjects?.filter(x => x.parentProjectIdentifier === parentProjectId) ||
    []

  const parentProjects = getParentProjects(projectsById, currentProjectId)
  const workspace = data?.workspace

  if (!workspace) {
    return { error: new ApolloError({ errorMessage: 'Workspace not found' }) }
  }

  const breadcrumbsData: BreadcrumbsData = {
    siblingProjects,
    parentProjects,
    workspace,
    parentProject,
    currentProject: currentProject || null,
  }

  const isInFlight =
    isNetworkRequestInFlight(projectsNetworkStatus) ||
    isNetworkRequestInFlight(workspaceNetworksStatus)

  return { isInFlight, error: projectsError || workspaceError, breadcrumbsData }
}

export const useProject = ({
  workspaceId,
  projectId,
  search,
  filters = [],
  isNestedProjectsOn,
}: UseProjectsProps) => {
  const {
    data: projectSharesData,
    networkStatus: projectSharesNetworkStatus,
    fetchMore: fetchMoreProjectShares,
    error: projectSharesError,
    refetch: refetchProjectShares,
  } = useGetProjectShares({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  const {
    data: collectionsData,
    networkStatus: collectionNetworkStatus,
    fetchMore: fetchMoreCollections,
    error: collectionsError,
    refetch: refetchProjectCollections,
  } = useGetProjectCollections({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  const {
    data: nestedProjectsData,
    networkStatus: nestedProjectsNetworkStatus,
    fetchMore: fetchMoreNestedProjects,
    error: nestedProjectsError,
    refetch: refetchNestedProjects,
  } = useGetNestedProjects({
    projectIdentifier: projectId,
    skip: !isNestedProjectsOn,
  })

  const {
    isInFlight: isInFlightBreadcrumbs,
    error: breadcrumbsError,
    breadcrumbsData,
  } = useProjectsBreadcrumbsData(workspaceId, projectId)

  useOnEvent(
    'workspaceShareRefresh',
    ({ workspaceIdentifier, projectIdentifier, onlyProjects }) => {
      if (workspaceIdentifier !== workspaceId) {
        // Not the workspace visible ignore then
        return
      }

      if (onlyProjects) {
        refetchNestedProjects()
        return
      }

      if (projectIdentifier === projectId) {
        refetchProjectShares()
      }

      refetchProjectCollections()
      refetchNestedProjects()
    }
  )

  useOnEvent('nestedProjectCreated', ({ parentProjectId }) => {
    if (parentProjectId !== projectId) return
    refetchNestedProjects()
  })

  useProjectBanner({ project: projectSharesData?.project, workspaceId })

  const loading =
    isInFlightBreadcrumbs ||
    isNetworkRequestInFlight(projectSharesNetworkStatus) ||
    isNetworkRequestInFlight(collectionNetworkStatus) ||
    isNetworkRequestInFlight(nestedProjectsNetworkStatus)

  const error =
    breadcrumbsError ||
    projectSharesError ||
    collectionsError ||
    nestedProjectsError

  const project = projectSharesData?.project
  const afterShares = project?.shares.meta.after ?? null
  const afterCollections =
    collectionsData?.project.collections.meta.after ?? null
  const afterNestedProjects =
    nestedProjectsData?.project.projects.meta.after ?? null

  const loadMoreSharesHandler = handleFetchMore(
    fetchMoreProjectShares,
    sharesEntriesPath,
    { dataIdFromObject, after: afterShares }
  )

  const loadMoreNestedProjectsHandler = handleFetchMore(
    fetchMoreNestedProjects,
    nestedProjectsEntriesPath,
    { dataIdFromObject, after: afterNestedProjects }
  )

  const loadMoreCollectionsHandler = handleFetchMore(
    fetchMoreCollections,
    collectionsEntriesPath,
    { dataIdFromObject, after: afterCollections }
  )

  return {
    data: {
      project: projectSharesData?.project,
      collections: collectionsData?.project.collections,
      nestedProjects: nestedProjectsData?.project.projects,
      breadcrumbs: breadcrumbsData,
    },
    loadMore: {
      project: loadMoreSharesHandler,
      collections: loadMoreCollectionsHandler,
      nestedProjects: loadMoreNestedProjectsHandler,
    },
    loading,
    error,
  }
}
