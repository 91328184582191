import React, { FC } from 'react'

import { Navbar, useForTablet } from '@sketch/components'
import { FrameGroupDetailsFragment } from '@sketch/gql-types'
import { ReactComponent as PlayIcon } from '@sketch/icons/play-20'
import { RouteParams } from '@sketch/modules-common'
import { Zoom, ZoomProps, ZoomTypes } from '../../../../../components/Zoom'
import { Link, useParams } from 'react-router-dom'

import ArtboardPaginationControls from '../../../components/ArtboardPaginationControls'
import HeaderNavigation from '../../../components/HeaderNavigation'
import { Pagination } from '../../../types'
import { ShareWithoutVersion, useVersioning } from '../../../../versioning'

import { NavbarItem } from './FrameGroupDetailHeader.styles'
import { useGridAndLayoutPropForZoomComponent } from '../../hooks'

const UntypedArtboardPaginationControls = ArtboardPaginationControls as any

type FrameGroupDetailHeaderEmbeddedProps = {
  share: ShareWithoutVersion
  frameGroup: FrameGroupDetailsFragment | undefined
  pagination?: Pagination
  showPrototypePlayButton: boolean
  onZoomChanged: ZoomProps['setZoom']
  currentZoom: ZoomTypes
}

export const FrameGroupDetailHeaderEmbedded: FC<FrameGroupDetailHeaderEmbeddedProps> = ({
  share,
  frameGroup,
  showPrototypePlayButton,
  pagination,
  currentZoom,
  onZoomChanged,
}) => {
  const { getPathname } = useVersioning()
  const { shareID } = useParams<RouteParams<'ARTBOARD_DETAIL' | 'FRAME'>>()

  const isTabletAndBigger = useForTablet()
  const showPagination = pagination && isTabletAndBigger

  const prototypePath = frameGroup?.uuid
    ? getPathname({
        routeKey: 'PROTOTYPE_PLAYER',
        routeParams: {
          shareID,
          // TODO: Rename these to frame group
          // https://linear.app/sketch/issue/SWEB-500/rename-common-components-between-artboards-and-frames-to
          currentArtboardUUID: frameGroup.uuid,
          prototypeArtboardUUID: frameGroup.uuid,
        },
      })
    : undefined

  const gridAndLayoutPropsForZoomComponent = useGridAndLayoutPropForZoomComponent()

  return (
    <Navbar>
      <Navbar.Section>
        <HeaderNavigation share={share} frameGroup={frameGroup} />
      </Navbar.Section>

      <Navbar.FixedWidthSection align="end">
        {prototypePath && showPrototypePlayButton && (
          <Link to={prototypePath} title="Play prototype">
            <Navbar.Button icon={PlayIcon} />
          </Link>
        )}

        {showPagination && (
          <NavbarItem>
            <UntypedArtboardPaginationControls
              current={pagination.current}
              total={pagination.total}
              showNumbers
            />
          </NavbarItem>
        )}

        <NavbarItem>
          <Zoom
            zoom={currentZoom}
            setZoom={onZoomChanged}
            gridAndLayout={gridAndLayoutPropsForZoomComponent}
            explicitControls
          />
        </NavbarItem>
      </Navbar.FixedWidthSection>
    </Navbar>
  )
}
