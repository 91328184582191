import React from 'react'
import { useRouteMatch, Redirect } from 'react-router'

import { routes, isDraftsRoute } from '@sketch/modules-common'

import ProjectShares, {
  ProjectSharesProps,
} from '../../components/ProjectShares'
import { useGetProjects } from '../../operations'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { LoadingState } from '@sketch/components'

interface ProjectsViewProps extends OmitSafe<ProjectSharesProps, 'projectId'> {
  workspace: WorkspaceMinimalFragment
  projectId?: string
  setProjectIdentifier?: (projectId: string) => void
}

/**
 * ProjectsView
 *
 * This view tries to determine if the project is a draft project or a normal project.
 * and renders the ProjectShares for the appropriate project.
 */
const ProjectsView = ({
  workspace,
  projectId: projectIdFromParams,
  setProjectIdentifier,
}: ProjectsViewProps) => {
  const { path } = useRouteMatch()

  const { identifier: workspaceId } = workspace

  const { rootDraftProjectsSorted, draftsProject, loading } = useGetProjects({
    workspaceId,
  })

  if (loading) {
    return <LoadingState />
  }

  const myDraftsProjectId = rootDraftProjectsSorted[0]?.project?.identifier
  const isDraftView = isDraftsRoute(path)

  const isDraftRouteUsingProjectId =
    draftsProject && myDraftsProjectId === projectIdFromParams

  if (isDraftRouteUsingProjectId) {
    // The draft project can also be accessed using the URL format of a normal project.
    // This is accepted but we need to redirect to the proper draft route for consistency
    //  but also so that the "Drafts" menu NavLink can set the active state.
    // i.e.: If project id "HELLO" is a draft project: workspace/ID/p/HELLO -> workspace/ID/drafts
    return <Redirect to={routes.WORKSPACE_DRAFTS.create({ workspaceId })} />
  }

  const projectId = isDraftView ? myDraftsProjectId! : projectIdFromParams!

  if (projectId !== projectIdFromParams) {
    // If the projectId has changed, call `setProjectIdentifier` so that
    // subscriptions are updated.
    setProjectIdentifier?.(projectId)
  }

  return <ProjectShares workspace={workspace} projectId={projectId} />
}

export default ProjectsView
