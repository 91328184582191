import { castError } from '@sketch/utils'
import ApolloClient from 'apollo-client'
import {
  readShareListItemFromCache,
  writeShareListItemToCache,
} from '@sketch/modules-common'
import { ErrorHandler } from '@sketch/tracing'
import {
  GetShareQuery,
  GetShareDocument,
  GetShareQueryVariables,
} from '@sketch/gql-types'

export const insertNewShare = async (
  client: ApolloClient<object>,
  shortId: string
) => {
  // This method inserts newly received share from versionUploadCompleteSubscription
  // into the shares list that it belongs to, so that the share appears within the UI
  // without having to refresh the page.

  // Share Query is performed to retrieve share data from the API because
  // useVersionUploadComplete subscription doesn't include ShareInfoFragment information
  // that we need for adding shares to ProjectShares, OrganizationShares, and UserShares cache
  try {
    const shareQuery = await client.query<
      GetShareQuery,
      GetShareQueryVariables
    >({
      query: GetShareDocument,
      variables: { shortId },
      fetchPolicy: 'no-cache',
    })

    const cachedShare = readShareListItemFromCache({
      cache: client,
      id: shortId,
    })
    const isCachedShareFinished =
      cachedShare?.version?.document?.renderStatus === 'FINISHED'

    // Pick whichever share is most updated whether it is the cached or the retrieved one
    const share = isCachedShareFinished ? cachedShare : shareQuery.data.share

    const workspaceId = shareQuery.data.share?.workspace.identifier

    if (!share || !workspaceId) {
      return
    }

    if (!isCachedShareFinished) {
      // Write share to cache only if there isn't a newer value
      try {
        writeShareListItemToCache({
          cache: client,
          share: { ...share },
        })
      } catch (e) {
        const error = castError(e)
        ErrorHandler.shouldNeverHappen(error.message)
      }
    }
  } catch (e) {
    const error = castError(e)
    ErrorHandler.shouldNeverHappen(error.message)
  }
}
