import React, { useMemo } from 'react'
import { useIsCameraMoving } from '@sketch-hq/sketch-web-renderer'
import { HoveredLayerOverlay } from './HoveredLayerOverlay'
import { MeasurementLine } from './MeasurementLine'
import { Orientation } from './types'
import {
  useInspectorHoveredElement,
  useInspectorContext,
  useMapPRMarinaRectToCanvasRelativeRect,
} from '../../hooks'
import {
  ElementType,
  SketchFrameElement,
  SketchElement,
  isLayerElement,
  findFrameElement,
  getFramesWithGridsAndLayouts,
} from '../../../../inspector'

import { SymbolInstanceOverlay } from './SymbolInstanceOverlay/SymbolInstanceOverlay'
import { useMeasurementData } from './useMeasurementData'
import { OverlayGrid } from './OverlayGrid'
import { OverlayLayout } from './OverlayLayout'
import { useFrameGroupContext } from '../../hooks/useFrameGroupContext'
import { SelectedElementOverlays } from './SelectedElementOverlays'
import { ElementRelativeContainer } from '../ElementRelativeContainer'

import * as S from './ArtboardDetailInspectorOverlay.styles'

type InspectorProps = {
  canvasContainerRef: React.RefObject<HTMLDivElement>
}

export function FrameGroupInspectorOverlay({
  canvasContainerRef,
}: InspectorProps) {
  const isCameraMoving = useIsCameraMoving()

  const { selectedElement, sketchSceneRootElement } = useInspectorContext()
  const { isGridEnabled, isLayoutEnabled } = useFrameGroupContext()

  const hoveredElement = useInspectorHoveredElement()

  /**
   * When we select a layer that is inside a symbol instance, we want to also
   * display the symbol layer (in purple) alongside of the selected layer (in red).
   */
  const symbolInstanceData = useMemo(() => {
    if (!selectedElement || !isLayerElement(selectedElement)) {
      return null
    }

    // If the currently selected element is a symbol instance use it,
    // otherwise check if it belongs to a symbol.
    const symbolLayerElement =
      selectedElement.type === ElementType.SymbolInstance
        ? selectedElement
        : selectedElement.parentSymbolLayerElement

    if (symbolLayerElement) {
      return {
        identifier: symbolLayerElement.prMarinaNode.getIdentifier(),
        bounds: symbolLayerElement.prMarinaNode.getAbsoluteLayerBounds(),
        symbolMaster: symbolLayerElement.symbolMaster,
      }
    }

    return null
  }, [selectedElement])

  const hoveredElementBounds = useMemo(() => {
    // Don't show hover rectangle on top of a selected layer that already has a red rectangle.
    const isSelectedElement =
      selectedElement?.identifier === hoveredElement?.identifier

    if (!hoveredElement || isSelectedElement) {
      return null
    }

    return hoveredElement?.prMarinaNode.getAbsoluteLayerBounds()
  }, [hoveredElement, selectedElement?.identifier])

  const shouldShowGridAndLayout = isGridEnabled || isLayoutEnabled

  // Show grid/layout if an artboard is selected or if we are in the artboard
  // detail view
  const frameElement =
    shouldShowGridAndLayout &&
    findFrameElement(selectedElement, sketchSceneRootElement)

  return (
    <S.Container $isHidden={Boolean(isCameraMoving)}>
      {shouldShowGridAndLayout && frameElement && (
        <GridAndLayout rootFrameElement={frameElement} />
      )}

      <MeasurementsLines
        selectedElement={selectedElement}
        hoveredElement={hoveredElement}
      />

      {hoveredElementBounds && hoveredElement && (
        <HoveredLayerOverlay
          bounds={hoveredElementBounds}
          element={hoveredElement}
        />
      )}

      <SelectedElementOverlays selectedElement={selectedElement} />

      {symbolInstanceData && (
        <SymbolInstanceOverlay
          bounds={symbolInstanceData.bounds}
          symbolMaster={symbolInstanceData.symbolMaster}
          canvasContainerRef={canvasContainerRef}
        />
      )}
    </S.Container>
  )
}

type GridAndLayoutItemProps = {
  frameElement: SketchFrameElement
}

function GridAndLayoutItem({ frameElement }: GridAndLayoutItemProps) {
  const { isGridEnabled, isLayoutEnabled } = useFrameGroupContext()
  const artboardBounds = frameElement.prMarinaNode.getAbsoluteBounds()
  const artboardBoundsRelative = useMapPRMarinaRectToCanvasRelativeRect(
    artboardBounds
  )
  const gridSettings =
    'gridSettings' in frameElement ? frameElement.gridSettings : undefined
  const layoutSettings =
    'layoutSettings' in frameElement ? frameElement.layoutSettings : undefined

  if (!artboardBoundsRelative || (!gridSettings && !layoutSettings)) {
    return null
  }

  return (
    // Create a container positioned exactly on top of the artboard.
    // The grid and the layout will be drawn inside this container.
    <ElementRelativeContainer element={frameElement}>
      {gridSettings && (
        <OverlayGrid
          isEnabled={isGridEnabled}
          gridSize={gridSettings.size}
          thickLineStep={gridSettings.thickLineStep}
        />
      )}
      {layoutSettings && (
        <OverlayLayout
          isEnabled={isLayoutEnabled}
          layoutSettings={layoutSettings}
          artboardWidth={artboardBoundsRelative.width}
          artboardHeight={artboardBoundsRelative.height}
        />
      )}
    </ElementRelativeContainer>
  )
}

type GridAndLayoutProps = {
  rootFrameElement: SketchElement
}

function GridAndLayout({ rootFrameElement }: GridAndLayoutProps) {
  const elements = useMemo(
    () => getFramesWithGridsAndLayouts(rootFrameElement),
    [rootFrameElement]
  )

  return (
    <>
      {elements.map(frameElement => (
        <GridAndLayoutItem
          frameElement={frameElement}
          key={frameElement.elementUUID}
        />
      ))}
    </>
  )
}

type MeasurementsLinesProps = {
  selectedElement: SketchElement | null
  hoveredElement: SketchElement | null
}
function MeasurementsLines({
  selectedElement,
  hoveredElement,
}: MeasurementsLinesProps) {
  const measurementData = useMeasurementData(selectedElement, hoveredElement)

  return (
    <>
      {measurementData?.horizontalLine && (
        <MeasurementLine
          line={measurementData.horizontalLine}
          orientation={Orientation.Horizontal}
        />
      )}

      {measurementData?.secondHorizontalLine && (
        <MeasurementLine
          line={measurementData.secondHorizontalLine}
          orientation={Orientation.Horizontal}
        />
      )}

      {measurementData?.verticalLine && (
        <MeasurementLine
          line={measurementData.verticalLine}
          orientation={Orientation.Vertical}
        />
      )}

      {measurementData?.secondVerticalLine && (
        <MeasurementLine
          line={measurementData.secondVerticalLine}
          orientation={Orientation.Vertical}
        />
      )}
    </>
  )
}
