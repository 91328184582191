import React, { forwardRef } from 'react'
import { ShareDropPayload, useShareDrop } from '../../shares'
import {
  SelectDropdownLinkItem,
  SelectDropdownLinkItemProps,
} from '@sketch/components'

interface DroppableLinkItemProps extends SelectDropdownLinkItemProps {
  forceHighlight?: boolean
  onDropped: (data: ShareDropPayload) => void
}

// TODO: Review react/display-name ESLint issues
//  see: https://github.com/sketch-hq/Cloud/issues/14818
// eslint-disable-next-line react/display-name
export const DroppableLinkItem = forwardRef(
  (props: DroppableLinkItemProps, ref) => {
    const { forceHighlight = false, onDropped, ...linkProps } = props
    const {
      isDraggedOver,
      kind,
      isDraggedOverItself,
      ...dragEvents
    } = useShareDrop({
      onDropped,
    })

    return (
      <SelectDropdownLinkItem
        forceHover={isDraggedOver && !isDraggedOverItself}
        forceActive={forceHighlight}
        ref={ref}
        {...linkProps}
        {...dragEvents}
      />
    )
  }
)
