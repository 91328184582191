import React from 'react'

import { ReactComponent as FolderClosedIcon } from '@sketch/icons/folder-closed-16'
import { ReactComponent as FolderOpenIcon } from '@sketch/icons/folder-open-16'
import { ReactComponent as LockIcon } from '@sketch/icons/lock-16'
import { ReactComponent as PeopleIcon } from '@sketch/icons/persons-two-16'
import { ProjectInSidebarAndHeaderFragment } from '@sketch/gql-types'
import { ReactComponent as MyDraftsProject } from '@sketch/icons/my-drafts-prism-16'

export interface ProjectIconProps {
  project: Partial<ProjectInSidebarAndHeaderFragment>
  isOpen?: boolean
}

export const ProjectIcon = (props: ProjectIconProps) => {
  const { project, isOpen } = props

  if (project.type === 'PERSONAL_DRAFTS') {
    return <MyDraftsProject />
  }

  switch (project.privacyIcon) {
    case 'GUESTS':
      return <PeopleIcon />

    case 'INVITED_WORKSPACE_MEMBERS':
      return <LockIcon />

    case 'WORKSPACE_MEMBERS':
    default:
      if (isOpen) {
        return <FolderOpenIcon />
      } else {
        return <FolderClosedIcon />
      }
  }
}
