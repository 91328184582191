import { hasPaymentDetails } from './payments'

import {
  GetCustomerProviderQuery,
  GetWorkspaceSettingsQuery,
  WorkspaceMinimalFragment,
  GetCustomerQuery,
  useGetCustomerSignupSourceQuery,
  useGetCustomerProviderQuery,
  DuplicationWorkspaceFragment,
} from '@sketch/gql-types'

import { isBillingHidden } from '@sketch/env-config'

type Customer = GetCustomerQuery['customer']

interface GetPermissionsArgs {
  customerProvider?: GetCustomerProviderQuery
  settings?: GetWorkspaceSettingsQuery['workspace']
  workspace: WorkspaceMinimalFragment
  customer?: Customer
  source?: string
  isProfileCreated?: boolean
}

export const getWorkspaceUserRolePermissions = (
  workspace?: WorkspaceMinimalFragment
) => {
  const isFinance = workspace?.userRole === 'FINANCE'
  const isPartner = workspace?.userRole === 'PARTNER'
  const isAdmin = workspace?.userRole === 'ADMIN'
  const isGuest = workspace?.userRole === 'GUEST'

  const canManageBillingSetting = isAdmin || isFinance || isPartner

  return {
    isFinance,
    isPartner,
    isGuest,
    isAdmin,
    canManageBillingSetting,
  }
}

export const getWorkspaceTypePermissions = (
  workspace: WorkspaceMinimalFragment
) => {
  const isPersonalWorkspace = workspace.type === 'PERSONAL'
  const isEducationWorkspace = workspace.type === 'STUDENT'
  const isBusinessWorkspace = workspace.type === 'BUSINESS'

  return { isPersonalWorkspace, isEducationWorkspace, isBusinessWorkspace }
}

export const getWorkspaceSettingsSSOPermissions = (
  settings?: GetWorkspaceSettingsQuery['workspace']
) => {
  return !!settings?.features?.ssoEnabled
}
export const isAppStoreSubscription = (provider?: GetCustomerProviderQuery) => {
  const isAppStoreSubscription =
    provider?.customer.subscriptionInfo.__typename === 'AppStoreSubscription'

  return isAppStoreSubscription
}

export const isTrialing = (customerProvider?: GetCustomerProviderQuery) => {
  const status = customerProvider?.customer?.subscriptionInfo?.status
  return status === 'TRIALING'
}

export const isAppStoreWorkspace = (source?: string | null) =>
  source === 'macappstore' || source === 'ios'

// Here we will group all the conditions for the cases where we can let the user subscribe via stripe and
// prevent showing the 'subscribe' button on the MAS app webview
const canSubscribeViaStripe = (
  source?: string,
  customerProvider?: GetCustomerProviderQuery
) => {
  // We won't let any workspace subscribed via App Store to subscribe via Stripe
  if (isAppStoreSubscription(customerProvider)) {
    return false
  }

  // If it's coming from 'macappstore' or 'ios' we don't let the user subscribe via stripe
  if (isAppStoreWorkspace(source)) {
    return false
  }

  // If it's not trialing on 'macappstore' or 'ios' means that it's coming from our app or the web, so we let subscribe via Stripe. Also,
  // we won't let them subscribe on Stripe while they are subscribed via App Store to prevent corner cases
  return true
}

export const useCanSubscribeViaStripe = (
  workspaceId: string,
  customerId?: string
) => {
  const {
    data: signupSource,
    loading: loadingSource,
  } = useGetCustomerSignupSourceQuery({
    variables: { identifier: workspaceId },
    skip: !workspaceId,
  })

  const {
    data: customerProvider,
    loading: loadingCustomer,
  } = useGetCustomerProviderQuery({
    variables: { customerId: customerId! },
    skip: !customerId,
  })

  const source = signupSource?.workspace?.customer?.source
  const loading = loadingSource || loadingCustomer

  return {
    canSubscribeStripe: canSubscribeViaStripe(source, customerProvider),
    source,
    customerProvider,
    loading,
  }
}

/**
 *
 * Aggregates a bunch of workspace permissions in a tidy object
 * to make it easier to use
 */
export const workspaceSettingsPermissions = ({
  workspace,
  customerProvider,
  customer,
  source,
  isProfileCreated = false,
}: GetPermissionsArgs) => {
  const {
    isFinance,
    isGuest,
    isAdmin,
    isPartner,
    canManageBillingSetting,
  } = getWorkspaceUserRolePermissions(workspace)

  const {
    isEducationWorkspace,
    isPersonalWorkspace,
    isBusinessWorkspace,
  } = getWorkspaceTypePermissions(workspace)

  const isIOS = isAppStoreSubscription(customerProvider)
  const isSubscribed = hasPaymentDetails(customer?.paymentDetails)
  const isMASTrial = isTrialing(customerProvider) && isAppStoreWorkspace(source)

  const showWorkspaceSettingsGeneral =
    !isPersonalWorkspace &&
    isAdmin &&
    !isFinance &&
    !isIOS &&
    (!isEducationWorkspace || (isEducationWorkspace && isSubscribed))

  const showWorkspaceSettingsBilling =
    !isPersonalWorkspace && canManageBillingSetting && !isBillingHidden

  const showWorkspaceSettingsSSO =
    !isPersonalWorkspace &&
    isAdmin &&
    !isIOS &&
    !isEducationWorkspace &&
    !isMASTrial

  const showWorkspaceSettingsProfile = isProfileCreated && isAdmin

  const showWorkspaceSettingsPeople = !isMASTrial
  const showWorkspacePermissionGroup =
    isAdmin && isBusinessWorkspace && workspace.features.permissionGroupsEnabled

  const hideNavigation = isIOS

  const isFinanceOrPartner = isFinance || isPartner
  const showDiscover = !isFinanceOrPartner
  const showCommunity = !isFinanceOrPartner

  const showUpsell =
    isAdmin &&
    !isBillingHidden &&
    customer?.subscriptionInfo?.currentPlan.__typename === 'CloudBillingPlan' &&
    customer?.subscriptionInfo?.currentPlan?.product === 'TEAMS'

  return {
    hideNavigation,
    isAdmin,
    isEducationWorkspace,
    isBusinessWorkspace,
    isFinance,
    isGuest,
    isIOS,
    isPartner,
    isPersonalWorkspace,
    showDiscover,
    showCommunity,
    showUpsell,
    showWorkspaceSettingsBilling,
    showWorkspaceSettingsGeneral,
    showWorkspaceSettingsSSO,
    showWorkspaceSettingsPeople,
    showWorkspaceSettingsProfile,
    showWorkspacePermissionGroup,
  }
}

export const showWorkspaceSettings = (
  workspace: WorkspaceMinimalFragment
): boolean => workspace.userRole !== 'GUEST'

// Note: `workspace.userCanEdit` is the only way to know if the user is an editor
export const userCanEditInWorkspace = (
  workspace: Pick<WorkspaceMinimalFragment, 'userCanEdit' | 'status'>
) => workspace.userCanEdit && workspace.status === 'ACTIVE'

// Note: `workspace.userCanAdminster` is the same as `workspace.userRole === 'ADMIN'`
export const userIsAdmin = (
  workspace: Pick<WorkspaceMinimalFragment, 'userCanAdminister' | 'userRole'>
) => workspace.userCanAdminister || workspace.userRole === 'ADMIN'

export const userIsEditorOrAdmin = (
  workspace: Pick<
    WorkspaceMinimalFragment,
    'userCanAdminister' | 'userCanEdit' | 'status' | 'userRole'
  >
) => userCanEditInWorkspace(workspace) || userIsAdmin(workspace)

export const userIsMember = (
  workspace: Pick<WorkspaceMinimalFragment, 'userRole'>
) => workspace.userRole === 'MEMBER' || workspace.userRole === 'ADMIN'

export const userCanUploadDocuments = (
  workspace: Pick<DuplicationWorkspaceFragment, 'uploadsRestrictedFor'>
) =>
  workspace.uploadsRestrictedFor === 'OTHERS' ||
  workspace.uploadsRestrictedFor === 'NONE'
