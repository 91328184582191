import { localStorageKeys } from '@sketch/constants'
import { getParsedItem, setStringifiedItem } from '@sketch/utils'

export interface AwsConfig {
  kmsArn: string
  awsRegion: string
  credentials: {
    accessKeyId: string
    secretAccessKey: string
    sessionToken?: string
  }
}

export type AllAwsConfig = {
  [key: string]: AwsConfig
}

export const setAwsConfig = (workspaceId: string, awsConfig: AwsConfig) => {
  const allAwsConfig = getAwsConfig()
  allAwsConfig[workspaceId] = awsConfig
  setStringifiedItem(localStorageKeys.awsConfig, allAwsConfig)
}

export const getAwsConfig = (): AllAwsConfig => {
  return getParsedItem(localStorageKeys.awsConfig) ?? {}
}

export interface ICryptoWorkerClient {
  configure(awsConfig: AllAwsConfig): Promise<void>
  terminate(): void
  encrypt(workspaceId: string, data: Uint8Array): Promise<Uint8Array>
  encryptText(workspaceId: string, data: string): Promise<string>
  decrypt(workspaceId: string, data: Uint8Array): Promise<Uint8Array>
  decryptText(workspaceId: string, data: string): Promise<string>
}

export class NullClient implements ICryptoWorkerClient {
  configure = async () => {}
  terminate = () => {}
  encrypt = async (workspaceId: string, data: Uint8Array) => {
    return new Promise<Uint8Array>((_, reject) => reject('ERROR'))
  }
  decrypt = async (workspaceId: string, data: Uint8Array) => {
    return new Promise<Uint8Array>((_, reject) => reject('ERROR'))
  }
  decryptText = async (workspaceId: string, data: string) => {
    return new Promise<string>((_, reject) => reject('ERROR'))
  }
  encryptText = async (workspaceid: string, data: string) => {
    return new Promise<string>((_, reject) => reject('ERROR'))
  }
}

export interface ITimeout {
  timeout: number
  callback: (() => void) | null
}

/**
 * This class provides two cryptographic encryption and decryption services in
 * two flavours:
 *
 *  * `encrypt` / `decrypt`: operates using the `Uint8Array` type;
 *  * `encryptText` / `decryptText`: operates using the `string` type;
 *
 * These functions communicate with the web worker using `MessageChannel`, but
 * provide a `async` interface for the caller. Additionally, each operation has
 * hard timeout of 5 seconds, in case the web worker isn’t working for whatever
 * reason.
 *
 * Before anything else, you must call the `configure` function with the aws
 * keys. Nothing works until you do so, as it’s impossible to do anything
 * without the proper credentials. The same is true when credentials expire.
 *
 * Finally, remember to call `terminate` when this instance is no longer
 * required. The timeout mechanism uses a timer that must be canceled and the
 * web worker terminated.
 *
 * Notice that you shouldn’t use this class directly. A `useCryptoWorker` hook
 * is available to manage the client’s lifecycle.
 */
export class CryptoWorkerClient implements ICryptoWorkerClient {
  private timer: NodeJS.Timer
  private cryptoWorker: Worker
  private timeouts: ITimeout[]

  constructor(worker?: Worker) {
    this.cryptoWorker = worker ?? new Worker('/crypto-worker.js')
    this.timeouts = []
    this.timer = setInterval(this.timeoutHandler, 1000)
  }

  configure = async (awsConfig: AllAwsConfig) => {
    const keys = Object.keys(awsConfig) as Array<keyof AllAwsConfig>
    await Promise.all(
      keys.map(
        (keyId): Promise<void> => {
          return this.sendMessage<void>({
            type: 'kms-config',
            data: {
              ...awsConfig[keyId],
              keyId: keyId,
            },
          })
        }
      )
    )
    return
  }

  terminate = () => {
    clearInterval(this.timer)
    this.cryptoWorker.terminate()
    // timeout all pending operations
    this.timeouts.forEach(timeout => {
      const callback = timeout.callback
      if (callback) {
        callback()
      }
    })
  }

  encrypt = (workspaceId: string, data: Uint8Array) => {
    return this.sendMessage<Uint8Array>(
      { type: 'encrypt', data: { keyId: workspaceId, data: data } },
      5 // timeout
    )
  }

  encryptText = (workspaceId: string, text: string) => {
    return this.sendMessage<string>(
      { type: 'encrypt-text', data: { keyId: workspaceId, data: text } },
      5 // timeout
    )
  }

  decrypt = (workspaceId: string, data: Uint8Array) => {
    return this.sendMessage<Uint8Array>(
      { type: 'decrypt', data: { keyId: workspaceId, data: data } },
      5 // timeout
    )
  }

  decryptText = (workspaceId: string, text: string) => {
    return this.sendMessage<string>(
      { type: 'decrypt-text', data: { keyId: workspaceId, data: text } },
      5 // timeout
    )
  }

  addTimeout = (timeout_secs: number, callback: () => void): ITimeout => {
    const timeout = {
      timeout: timeout_secs,
      callback,
    }
    this.timeouts.push(timeout)
    return timeout
  }

  private sendMessage = <T>(
    message: any,
    timeout_secs?: number
  ): Promise<T> => {
    const channel = new MessageChannel()
    const promise = new Promise<T>((resolve, reject) => {
      const timeout: ITimeout = {
        timeout: timeout_secs ?? 0,
        callback: () => reject('TIMEOUT'),
      }
      if (timeout_secs) {
        this.timeouts.push(timeout)
      }
      channel.port1.addEventListener('message', (event: MessageEvent) => {
        timeout.callback = null
        if (event.data instanceof Error) {
          reject(event.data)
        } else {
          resolve(event.data.data)
        }
      })
    })
    channel.port1.start()
    this.cryptoWorker.postMessage(message, [channel.port2])
    return promise
  }

  timeoutHandler = () => {
    const expired: (() => void)[] = []
    let at = this.timeouts.length
    while (at-- > 0) {
      const timeout = this.timeouts[at]
      const remaining = timeout.timeout
      if (timeout.timeout > 0 && timeout.callback) {
        timeout.timeout = remaining - 1
      } else {
        this.timeouts.splice(at--, 1)
        const callback = timeout.callback
        if (callback) {
          expired.push(callback)
        }
      }
    }
    expired.forEach(callback => callback())
  }
}
