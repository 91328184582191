import React, { FC, useEffect, useState, useMemo } from 'react'
import { useRouteMatch } from 'react-router'

import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
  VersionFragment,
} from '@sketch/gql-types'
import { IS_EMBEDDED } from '@sketch/constants'
import { shortcuts } from '@sketch/utils'
import { ShareWithoutVersion, useVersioning } from '../../../../versioning'
import { versionedRoutes } from '@sketch/modules-common'
import {
  useForTablet,
  MenuIconButton,
  TooltipShortcut,
  DocumentHead,
} from '@sketch/components'

import { useHasNewNotifications } from '../../../../annotations/hooks'

import HeaderNavigation from '../../../components/HeaderNavigation'
import VersionLinkButton from '../../../components/VersionLinkButton'
import PlayPrototypeLink from '../../../components/PlayPrototypeLink'
import DerivedHeaderActions from '../../../components/DerivedHeaderActions'

import { NavbarItem, Wrapper } from './FrameGroupDetailHeader.styles'
import { FrameGroupDetailSettingsDropdown } from '../FrameGroupDetailSettingsDropdown'
import { DocumentSidebarLayoutExtraProps } from '../../../components/DocumentSidebarLayout'

import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-24'
import { ReactComponent as CodeBlock } from '@sketch/icons/code-block-24'
import { ReactComponent as ClockReWind } from '@sketch/icons/clock-rewind-24'

import {
  useShareSidebarTab,
  getActivePanels,
} from '../../../components/ShareSidebarTabContext'
import { VerticalDivider } from '../../../components/NavbarItem'

type FrameGroupDetailHeaderProps = {
  share: ShareWithoutVersion
  frameGroup: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  isViewingLatestVersion: boolean
  isPrototypePlayEnabled: boolean
  userCanOpenInApp: boolean
  currentVersion: VersionFragment | null
} & Pick<
  DocumentSidebarLayoutExtraProps,
  'setSidebarLeftOpen' | 'toggleSidebarRight' | 'isSidebarRightOpen'
>

export const FrameGroupDetailHeader: FC<FrameGroupDetailHeaderProps> = ({
  share,
  frameGroup,
  toggleSidebarRight,
  isSidebarRightOpen,
  isPrototypePlayEnabled,
  isViewingLatestVersion,
  userCanOpenInApp,
  currentVersion,
}) => {
  const { identifier, userCanInspect, versionTypeAvailableToUser } = share
  const { path } = useRouteMatch()
  const isTabletAndBigger = useForTablet()
  const { latestVersionId } = useVersioning()

  const activePanels = useMemo(
    () =>
      getActivePanels({
        path,
        canInspect: userCanInspect,
        // Even when the comments are not allowed, the comments panel should be available
        canComment: true,
      }),
    [path, userCanInspect]
  )

  /**
   * Save the tabs the user has access and
   * update if "shouldShowCommentTab" and "shouldShowInspector" updates
   */
  const [segments, setSegments] = useState(activePanels)

  useEffect(() => {
    setSegments(activePanels)
  }, [activePanels])

  const { activeSegment, toggleSegment } = useShareSidebarTab(segments, {
    isSidebarRightOpen,
    toggleSidebarRight,
  })

  const hasNewNotifications = useHasNewNotifications()

  const showLatestVersionButton =
    isTabletAndBigger &&
    !isViewingLatestVersion &&
    !IS_EMBEDDED &&
    // never show if user can only access latest version (it will update automatically)
    versionTypeAvailableToUser !== 'LATEST'

  return (
    <>
      {frameGroup?.name && (
        <DocumentHead title={`${frameGroup.name} - ${share.name}`} />
      )}

      <HeaderNavigation share={share} frameGroup={frameGroup} />

      <DerivedHeaderActions
        share={share}
        currentVersion={currentVersion}
        userCanOpenInApp={userCanOpenInApp}
        isViewingLatestVersion={isViewingLatestVersion}
      />
      <Wrapper>
        {frameGroup && showLatestVersionButton && (
          <NavbarItem>
            <VersionLinkButton
              to={versionedRoutes.FRAME.VERSION.create({
                frameUUID: frameGroup.uuid,
                shareID: identifier,
                versionShortId: latestVersionId,
              })}
            >
              See Latest Version
            </VersionLinkButton>
          </NavbarItem>
        )}
        {frameGroup && isTabletAndBigger && frameGroup.isFlowHome && (
          <NavbarItem>
            <PlayPrototypeLink
              isPrototypePlayEnabled={isPrototypePlayEnabled}
              frameGroupUUID={frameGroup.uuid}
              shareIdentifier={identifier}
              representation="navbar"
            />
          </NavbarItem>
        )}
        {frameGroup && (
          <NavbarItem>
            <FrameGroupDetailSettingsDropdown
              shareIdentifier={identifier}
              frameGroup={frameGroup}
              subscriptionStatus={frameGroup.subscriptionStatus}
            />
          </NavbarItem>
        )}
        {isTabletAndBigger && (
          <>
            <VerticalDivider />
            <NavbarItem data-testid="panel-triggers-header">
              <MenuIconButton
                description={
                  <>
                    Versions{' '}
                    <TooltipShortcut>
                      Press {shortcuts.version.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                aria-current={activeSegment === 'Version'}
                onClick={() => toggleSegment('Version')}
              >
                <ClockReWind role="img" />
                <span className="sr-only">Versions</span>
              </MenuIconButton>

              {userCanInspect && (
                <MenuIconButton
                  description={
                    <>
                      Inspect{' '}
                      <TooltipShortcut>
                        Press {shortcuts.inspect.toUpperCase()}
                      </TooltipShortcut>
                    </>
                  }
                  aria-current={activeSegment === 'Inspect'}
                  onClick={() => toggleSegment('Inspect')}
                >
                  <CodeBlock role="img" />
                  <span className="sr-only">Inspect</span>
                </MenuIconButton>
              )}

              <MenuIconButton
                description={
                  <>
                    Comments{' '}
                    <TooltipShortcut>
                      Press {shortcuts.comment.toUpperCase()}
                    </TooltipShortcut>
                  </>
                }
                data-testid="comment-tab"
                aria-current={activeSegment === 'Comment'}
                onClick={() => toggleSegment('Comment')}
                $isHighlighted={hasNewNotifications}
              >
                <ChatBubbles role="img" />
                <span className="sr-only">Comments</span>
              </MenuIconButton>
            </NavbarItem>
          </>
        )}
      </Wrapper>
    </>
  )
}
