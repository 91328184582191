import React, { useCallback } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import {
  FrameGroupDetailsFragment,
  ProjectDetailsFragment,
  ProjectFragment,
} from '@sketch/gql-types'
import { IS_EMBEDDED } from '@sketch/constants'
import {
  useForLargerThanMobile,
  IsEmbedded,
  NotEmbedded,
} from '@sketch/components'
import { formatProjectName, isMyDrafts } from '../../../projects/utils'
import { ShareWithoutVersion } from '../../../versioning'
import {
  RouteParams,
  routes,
  useUserSignedIn,
  CWV_ROUTES,
  useFlag,
} from '@sketch/modules-common'

import { NavigationContainer } from './HeaderNavigation.styles'
import NavigationItem from './NavigationItem'
import { FramesNavigationItem } from './FramesNavigationItem'
import PagesNavigationItem from './PagesNavigationItem'
import CWVGroupNavigationItem from './CWVGroupNavigationItem'
import Breadcrumbs from './Breadcrumbs'
import { useShareViewsNavigation } from '../../utils/shareViewsNavigation'
import { ProjectsDropdown } from './ProjectsDropdown'

// get label and link for PROJECT item
const useGetProjectNav = (workspaceId: string) => {
  const isNestedProjectsOn = useFlag('nested-projects')
  const isMobile = !useForLargerThanMobile()
  const isSignedIn = useUserSignedIn()

  const getProjectNav = useCallback(
    (project: Pick<ProjectFragment, 'type' | 'name' | 'identifier'>) => {
      const projectName = project ? formatProjectName(project) : ''
      const projectId = project?.identifier ?? ''

      if (!projectName || !isSignedIn || IS_EMBEDDED || isMobile) {
        return null
      }

      const isMyDraftsProject = isNestedProjectsOn
        ? isMyDrafts(project as ProjectDetailsFragment)
        : project?.type === 'PERSONAL_DRAFTS'

      const projectLink = isMyDraftsProject
        ? routes.WORKSPACE_DRAFTS.create({ workspaceId })
        : routes.WORKSPACE_PROJECT.create({ projectId, workspaceId })

      return { projectName, projectLink }
    },
    [isMobile, isNestedProjectsOn, isSignedIn, workspaceId]
  )

  return getProjectNav
}

// check PAGE item visibility
const usePageNav = () => {
  const match = useRouteMatch<RouteParams<'SHARE_PAGE_VIEW'>>()
  const isCwvRoute = !!CWV_ROUTES.find((route: string) => route === match.path)

  // hide on CWV route or if number of pages < 2
  return !isCwvRoute
}

const useCollectionNav = (share: ShareWithoutVersion) => {
  const { collection, project, workspace } = share

  if (!collection || !project) {
    return null
  }

  const link = routes.WORKSPACE_COLLECTION.create({
    workspaceId: workspace.identifier,
    projectId: project.identifier,
    collectionId: collection.identifier,
  })

  const name = collection.name

  return { link, name }
}

export type HeaderNavigationProps = {
  share: ShareWithoutVersion
  frameGroup?: FrameGroupDetailsFragment
  className?: string
}

const getProjectGroups = (share: ShareWithoutVersion) => {
  const projects = [
    ...(share.project?.parentProjects || []),
    ...(share.project ? [share.project] : []),
  ]

  const direct = projects.pop()

  if (projects.length === 1) {
    const [root] = projects
    return { root, direct, collapsed: [] }
  }

  return { root: undefined, direct, collapsed: projects }
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  share,
  frameGroup,
}) => {
  const workspaceId = share.workspace.identifier

  const projects = getProjectGroups(share)
  const getProjectNav = useGetProjectNav(workspaceId)

  const rootProjectNav = projects.root && getProjectNav(projects.root)
  const directProjectNav = projects.direct && getProjectNav(projects.direct)

  const showPage = usePageNav()
  const collectionNav = useCollectionNav(share)
  const { previousView } = useShareViewsNavigation()
  const location: {
    state?: { groupId: string; symbolName: string }
  } = useLocation()

  const documentName = share.name
  const documentLink = routes.SHARE_VIEW.create({ shareID: share.identifier })
  const collapseDocument = showPage && !!frameGroup

  // make sure we're coming from a symbol and its data is set
  const isSymbolsView =
    previousView?.view === 'symbols' &&
    location.state?.groupId &&
    location.state?.symbolName

  return (
    <NavigationContainer>
      <NotEmbedded>
        <Breadcrumbs>
          {rootProjectNav && (
            <NavigationItem
              collapsed={true}
              to={rootProjectNav.projectLink}
              icon="project"
              label={rootProjectNav.projectName}
            />
          )}
          {projects.collapsed.length > 0 && (
            <ProjectsDropdown
              projects={projects.collapsed}
              workspaceId={workspaceId}
            />
          )}
          {directProjectNav && (
            <NavigationItem
              collapsed={true}
              to={directProjectNav.projectLink}
              icon="project"
              label={directProjectNav.projectName}
            />
          )}
          {collectionNav && (
            <NavigationItem
              collapsed={true}
              to={collectionNav.link}
              icon="collection"
              label={collectionNav.name}
            />
          )}
          <NavigationItem
            collapsed={collapseDocument}
            to={documentLink}
            icon={share.type === 'TEMPLATE' ? 'template' : 'document'}
            label={documentName}
          />
          {isSymbolsView ? (
            <CWVGroupNavigationItem
              shareIdentifier={share.identifier}
              groupId={location.state?.groupId ?? ''}
              symbolName={location.state?.symbolName ?? ''}
            />
          ) : (
            <PagesNavigationItem frameGroup={frameGroup} share={share} />
          )}
          {!isSymbolsView && frameGroup && (
            <FramesNavigationItem
              frameGroup={frameGroup}
              shareIdentifier={share.identifier}
            />
          )}
        </Breadcrumbs>
      </NotEmbedded>
      <IsEmbedded>
        <Breadcrumbs>
          {!frameGroup && (
            <NavigationItem
              to={documentLink}
              icon="document"
              label={documentName}
            />
          )}
          {!frameGroup && (
            <PagesNavigationItem frameGroup={frameGroup} share={share} />
          )}
          {frameGroup && (
            <FramesNavigationItem
              frameGroup={frameGroup}
              shareIdentifier={share.identifier}
            />
          )}
        </Breadcrumbs>
      </IsEmbedded>
    </NavigationContainer>
  )
}

export default HeaderNavigation
