import { GenericError } from '@sketch/components'
import React, { useEffect } from 'react'
import { ProjectIcon } from '../../components/ProjectIcon'
import { useTreeNodeState, useTreeState } from './state'
import { TreeNode, TreeNodeSkeleton } from './TreeNode'
import { Icon } from './TreeNode.styles'

import { useGetProjectsLazy } from '../../operations'
import { useOnListChange } from './state/useOnListChange'
import { ProjectsTreeDataNode } from './state/treeState.context'
import { ProjectInSidebarAndHeaderFragment } from '@sketch/gql-types'
import { useCreateSimpleProject } from '../../hooks/useCreateProject'
import { isTruthy } from '@sketch/utils'

export interface ProjectTreeNodeProps {
  node: ProjectsTreeDataNode
  workspaceId: string
  depth: number
}

export function ProjectTreeNode(props: ProjectTreeNodeProps) {
  const { node, depth, workspaceId } = props
  const { payload: project, id } = node
  const { createProject } = useCreateSimpleProject()
  const treeState = useTreeState()

  const children = treeState.tree.getChildren(node)

  if (project?.__typename !== 'Project') return null

  const standardOrDraftProject = project as Partial<ProjectInSidebarAndHeaderFragment>

  return (
    <TreeNode
      key={id}
      id={id}
      depth={depth}
      onCreateProject={async name => {
        const result = await createProject({
          workspaceId: workspaceId,
          name,
          parentProjectIdentifier: project.identifier,
        })
        return result?.identifier
      }}
      icon={open => (
        <Icon>
          <ProjectIcon project={standardOrDraftProject} isOpen={open} />
        </Icon>
      )}
      hasChildren={children.length > 0}
      label={<div>{project.name}</div>}
    >
      {children.map(child => {
        return (
          <ProjectTreeNode
            key={child.id}
            node={child}
            depth={depth + 1}
            workspaceId={workspaceId}
          />
        )
      })}
    </TreeNode>
  )
}

export interface ProjectsTreeNodesProps {
  workspaceId: string
}

export const ProjectTreeNodes = (props: ProjectsTreeNodesProps) => {
  const { workspaceId } = props

  const treeState = useTreeState()
  const { isOpen } = useTreeNodeState(workspaceId)
  const workspaceNode = treeState.tree.getNode(workspaceId)

  const { getProjects, loading, error, allProjects } = useGetProjectsLazy()

  useOnListChange(allProjects || [], (items, isFirstChange) => {
    const nodesWithChildren = new Set<string>(
      items.map(x => x.parentProjectIdentifier).filter(isTruthy)
    )

    treeState.setChildrenTree({
      list: items,
      itemParams: x => ({
        id: x.identifier,
        parentId: x.parentProjectIdentifier || workspaceId,
        hasChildren: nodesWithChildren.has(x.identifier),
      }),
    })

    if (
      isFirstChange &&
      treeState.currentDestinationId &&
      allProjects?.find(x => x.identifier === treeState.currentDestinationId)
    ) {
      treeState.setOpenToOnce(treeState.currentDestinationId)
    }
  })

  useEffect(() => {
    if (isOpen) getProjects({ variables: { workspaceId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (loading) {
    return (
      <div>
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
      </div>
    )
  }

  if (error) {
    return <GenericError />
  }

  const projectNodes = treeState.tree.getChildren(workspaceNode)

  return (
    <>
      {projectNodes.map(x => (
        <ProjectTreeNode
          key={x.id}
          depth={1}
          node={x}
          workspaceId={workspaceId}
        />
      ))}
    </>
  )
}
