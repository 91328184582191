import {
  xpath,
  encrypt,
  decrypt,
  PathMut,
  Env,
  all,
} from '@sketch/modules-common'
import {
  ApolloLink,
  FetchResult,
  Operation,
  fromPromise,
  toPromise,
} from 'apollo-link'

type EncryptFunc = (env: Env, request: Operation) => Promise<Operation>

type DecryptFunc = (env: Env, response: FetchResult) => Promise<FetchResult>

type EncryptionConfig = {
  // the operation name
  [key: string]: {
    // mutation variables to encrypt
    variables?: PathMut[][]
    // query/mutation fields to decrypt
    fields?: PathMut[][]
  }
}

/**
 * This variable contains all operations that must be processed by this
 * encryption link.
 *
 * The key is the operation name, as it appears in the query/mutation. Then,
 * declare all variables/fields which should be processed for that particular
 * query/mutation.
 *
 * Example:
 *
 * Encrypt the `name` and `description` variables of myMutation:
 *
 * ```
 *   { myMutation: {
 *       variables: [
 *         [ encrypt('name) ],
 *         [ encrypt('description') ]
 *       ]
 *   }
 * ```
 */
const ENCRYPTION_CONFIG: EncryptionConfig = {
  shareUpdate: {
    variables: [['share', encrypt('name')]],
    fields: [['shareUpdate', 'share', decrypt('extName', 'name')]],
  },
  getShares: {
    fields: [
      ['workspace', 'shares', 'entries', all(), decrypt('extName', 'name')],
    ],
  },
}

export const encryptionLink = () =>
  new ApolloLink((request, forward) => {
    const env = getenv(request)
    const encryptFunc = getEncryptFunc(request)
    if (encryptFunc && env) {
      return fromPromise(
        encryptFunc(env, request).then(request => toPromise(forward(request)))
      )
    }
    return forward(request)
  })

export const decryptionLink = () =>
  new ApolloLink((request, forward) => {
    const env = getenv(request)
    const decryptFunc = getDecryptFunc(request)
    if (decryptFunc && env) {
      return fromPromise(
        toPromise(forward(request)).then(response => decryptFunc(env, response))
      )
    }
    return forward(request)
  })

const getEncryptFunc = (request: Operation): EncryptFunc | undefined => {
  const encryptor = ENCRYPTION_CONFIG[request.operationName] ?? {}
  if (encryptor.variables) {
    return encryptVariables(encryptor.variables)
  }
}

const getDecryptFunc = (request: Operation): DecryptFunc | undefined => {
  const decryptor = ENCRYPTION_CONFIG[request.operationName] ?? {}
  if (decryptor.fields) {
    return decryptFields(decryptor.fields)
  }
}

const encryptVariables = (paths: PathMut[][]): EncryptFunc => {
  return (env, request) => {
    return Promise.all(
      paths.map(path => xpath(request.variables, path, env))
    ).then(() => request)
  }
}

const decryptFields = (paths: PathMut[][]): DecryptFunc => {
  return async (env, response) => {
    return Promise.all(paths.map(path => xpath(response.data, path, env)))
      .catch(() => response)
      .then(() => response)
  }
}

const getenv = (request: Operation): Env | undefined => {
  const context = request.getContext()
  return context.encryption as Env
}
