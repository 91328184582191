import React, { useRef } from 'react'
import { ComponentDescriptionContextProvider } from '../../../ComponentsWebView/context'
import { DocumentSidebarLayoutExtraProps } from '../../../components/DocumentSidebarLayout'
import { IsEmbedded, NotEmbedded, useForTablet } from '@sketch/components'
import { Redirect, RouteComponentProps } from 'react-router'
import { EmbeddedError, RouteParams, routes } from '@sketch/modules-common'
import { ShareWithoutVersion } from '../../../../versioning'
import {
  ArtboardDetailInfoFragment,
  FrameDetailInfoFragment,
  VersionFragment,
} from '@sketch/gql-types'
import { Pagination } from '../../../types'
import { ApolloError } from 'apollo-client'
// TODO: Rename ArtboardDetailView components to FrameGroup
// https://linear.app/sketch/issue/SWEB-500/rename-common-components-between-artboards-and-frames-to
import {
  FrameGroupCanvas,
  FrameGroupDetailHeader,
  FrameGroupDetailHeaderEmbedded,
  useGridAndLayoutPropForZoomComponent,
} from '../../../ArtboardDetailView'
import { FramePaginationControls } from '../FramePaginationControls'
import { Panel } from '../../../components/Panel'
import { OnboardingPanelFloatingPanels } from '../../../PageCanvasView/OnboardingPanelFloatingPanels'
import { FloatingZoom } from '../FloatingZoom'
import { useCanvasZoom } from '../../../../../components/WebRendererCanvas/hooks'
import { CustomFontsMessage } from '../../../components/CustomFontsMessage'
import { DraftAnnotationNotifier } from '../../../../annotations/components'
import { IS_EMBEDDED } from '@sketch/constants'
import {
  DocumentMobileToolbar,
  isMobileToolboxItem,
} from '../../../components/DocumentMobileToolbar'
import { usePlaceDraftAnnotation } from '../../../../annotations/hooks'

export type FrameViewViewRouteProps = RouteComponentProps<
  Partial<RouteParams<'FRAME'>>
>

export interface FrameViewProps {
  layoutProps: DocumentSidebarLayoutExtraProps
  share: ShareWithoutVersion
  currentVersion: VersionFragment | undefined
  frame: ArtboardDetailInfoFragment | FrameDetailInfoFragment
  isViewingLatestVersion: boolean
  isPrototypePlayEnabled: boolean
  pagination: Pagination | undefined
  frameIdAtLatestVersion: string | undefined
  error: ApolloError | undefined
  loading: boolean
}

export const FrameView = ({
  share,
  frame,
  isViewingLatestVersion,
  isPrototypePlayEnabled,
  currentVersion,
  layoutProps,
  pagination,
  error,
  loading,
}: FrameViewProps) => {
  const {
    HeaderPortal,
    SidebarRightPortal,
    setSidebarLeftOpen,
    toggleSidebarRight,
    isSidebarRightOpen,
  } = layoutProps

  const [zoom, setZoom] = useCanvasZoom()

  const isValidPagination =
    Number.isInteger(pagination?.current) && Number.isInteger(pagination?.total)

  const pageUUID = frame?.page?.uuid
  const frameUUID = frame?.uuid

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger
  const canvasRef = useRef<HTMLDivElement>(null)

  const MOBILE_TOOLBAR_ITEMS = [
    'zoom',
    frame?.isFlowHome && 'prototype',
    'add-comment',
    'comments',
    'versions',
  ].filter(isMobileToolboxItem)

  const [placeDraftAnnotation, setPlacingDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  const gridAndLayoutForZoomComponent = useGridAndLayoutPropForZoomComponent()

  return (
    <ComponentDescriptionContextProvider>
      <HeaderPortal>
        <NotEmbedded>
          <FrameGroupDetailHeader
            share={share}
            frameGroup={frame}
            setSidebarLeftOpen={setSidebarLeftOpen}
            toggleSidebarRight={toggleSidebarRight}
            isSidebarRightOpen={isSidebarRightOpen}
            isViewingLatestVersion={isViewingLatestVersion}
            isPrototypePlayEnabled={isPrototypePlayEnabled}
            userCanOpenInApp={!!currentVersion?.document?.userCanOpenInApp}
            currentVersion={currentVersion ?? null}
          />

          {isValidPagination && (
            <FramePaginationControls
              current={pagination!.current!}
              total={pagination!.total!}
            />
          )}

          <Panel
            share={share}
            version={currentVersion || undefined}
            userCanSeeComments
            userCanInspect={share.userCanInspect}
            SidebarRightPortal={SidebarRightPortal}
            frameGroup={frame}
          />

          <OnboardingPanelFloatingPanels />

          <FloatingZoom />
        </NotEmbedded>

        <IsEmbedded>
          {!error && !loading && (
            <FrameGroupDetailHeaderEmbedded
              share={share}
              frameGroup={frame}
              pagination={pagination}
              currentZoom={zoom}
              onZoomChanged={setZoom}
              showPrototypePlayButton={
                (frame && frame.isFlowHome && isPrototypePlayEnabled) || false
              }
            />
          )}
        </IsEmbedded>
      </HeaderPortal>

      <>
        <CustomFontsMessage
          userCanEdit={share.userCanEdit}
          shareIdentifier={share.identifier}
          hasMissingFonts={!!frame?.hasMissingFonts}
        />

        {error ? (
          <>
            <NotEmbedded>
              <Redirect
                to={routes.SHARE_VIEW.create({
                  shareID: share.identifier,
                })}
              />
            </NotEmbedded>
            <IsEmbedded>
              <EmbeddedError for="artboard" />
            </IsEmbedded>
          </>
        ) : (
          pageUUID &&
          frameUUID && (
            <>
              {!isMobile && <DraftAnnotationNotifier canvasRef={canvasRef} />}

              <FrameGroupCanvas
                pageUUID={pageUUID}
                artboardUUID={frameUUID}
                ref={canvasRef}
              />
            </>
          )
        )}
      </>

      {!IS_EMBEDDED && isMobile && (
        <DocumentMobileToolbar
          zoom={zoom}
          setZoom={setZoom}
          isPrototypePlayEnabled={isPrototypePlayEnabled}
          shareIdentifier={share.identifier}
          items={MOBILE_TOOLBAR_ITEMS}
          artboardUUID={frame?.uuid || ''}
          isDraftAnnotationEnabled={placeDraftAnnotation}
          setPlaceDraftAnnotation={setPlacingDraftAnnotation}
          gridAndLayout={gridAndLayoutForZoomComponent}
        />
      )}
    </ComponentDescriptionContextProvider>
  )
}
