import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useToast } from '@sketch/toasts'

import {
  Wrapper,
  Name,
  Form,
  Button,
} from './WorkspaceSettingsDetailsPanel.styles'

import { WorkspaceLogo, Input } from '@sketch/components'
import { WorkspaceSettingsLogoUpload } from '../WorkspaceSettingsLogoUpload'

import {
  useUpdateWorkspaceMutation,
  useDeleteWorkspaceAvatarMutation,
  UpdateWorkspaceDocument,
  UpdateWorkspaceMutation,
} from '@sketch/gql-types'

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("The workspace name can't be blank"),
})

interface WorkspaceSettingsDetailsPanelProps {
  name: string
  id: string
  avatar?: string
  isUserAdministrator?: boolean
}

export const WorkspaceSettingsDetailsPanel: React.FC<WorkspaceSettingsDetailsPanelProps> = props => {
  const { name, id, avatar, isUserAdministrator } = props
  const [logo, setLogo] = useState(avatar)

  const { showToast } = useToast()

  const [updateWorkspace] = useUpdateWorkspaceMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Workspace renamed')
    },
  })

  const [updateAvatar] = useUpdateWorkspaceMutation({
    onError: 'show-toast',
    onCompleted: data => {
      showToast('New Workspace image added')
    },
    ignoreResults: true,
    update: (cache, { data }) => {
      /**
       * The BE response of `updateAvatar` mutation will always return the same small and large
       * avatar URL fields even when uploading different images.
       * Due to this, React was not rerendering (props didn't change) and Apollo was not updating the fragment
       * To fix this, we use the `update` mutation method to append a timestamp to the small and large
       * avatar image URL. This will make React and Apollo to react properly to avatar image uploads
       */
      const workspace = data?.updateWorkspace.workspace
      const updatedWorkspace = {
        ...workspace!,
        avatar: workspace?.avatar
          ? {
              ...workspace.avatar,
              small: workspace.avatar.small + `?s=${new Date().getTime()}`,
              large: workspace.avatar.large + `?s=${new Date().getTime()}`,
            }
          : null,
      }

      setLogo(updatedWorkspace.avatar?.large ?? undefined)

      cache.writeQuery<UpdateWorkspaceMutation>({
        data: {
          __typename: 'RootMutationType',
          updateWorkspace: {
            __typename: 'UpdateWorkspaceResponse',
            workspace: updatedWorkspace,
          },
        },
        query: UpdateWorkspaceDocument,
      })
    },
  })

  const [deleteWorkspace] = useDeleteWorkspaceAvatarMutation({
    variables: { workspaceId: id },
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Workspace image removed', 'positive')
      setLogo(undefined)
    },
  })

  if (isUserAdministrator) {
    return (
      <Wrapper>
        <WorkspaceSettingsLogoUpload
          workspaceName={name}
          logo={logo}
          onDrop={async avatar => {
            await updateAvatar({
              variables: {
                input: {
                  identifier: id,
                  avatar,
                },
              },
            })
          }}
          onRemove={async () => {
            await deleteWorkspace()
          }}
          onError={message => {
            showToast(message, 'negative')
          }}
        />

        <Formik
          initialValues={{ name }}
          onSubmit={async (values, actions) => {
            const { name } = validationSchema.cast(values)

            await updateWorkspace({
              variables: {
                input: {
                  identifier: id,
                  name,
                },
              },
            })

            actions.resetForm()
          }}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            dirty,
            errors,
            touched,
            isValid,
            isSubmitting,
          }) => (
            <Form>
              <Form.Field
                mb={0}
                name="text"
                label="Name"
                errorText={touched.name ? errors.name : undefined}
              >
                <Input
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Field>
              <Button
                variant="secondary"
                size="40"
                type="submit"
                disabled={!dirty || !isValid}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <WorkspaceLogo size="64px" workspaceName={name} src={logo} />
      <Name>{name}</Name>
    </Wrapper>
  )
}
