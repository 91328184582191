import React from 'react'
import { routes } from '@sketch/modules-common'

import {
  useGetProjectsQuery,
  useGetProjectsLazyQuery,
  GetProjectsQueryVariables,
  GetProjectsQuery,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'

import { ProjectLink } from '../types'

type ProjectsById = {
  [key: string]: ProjectLink
}

export interface UseProjectsSwitchProps
  extends OmitSafe<GetProjectsQueryVariables, 'workspaceId'> {
  workspaceId: string
}

export const useGetProjects = ({
  workspaceId,
  after,
}: UseProjectsSwitchProps) => {
  const { data, ...query } = useGetProjectsQuery({
    variables: { workspaceId, after },
  })

  const {
    projectsById,
    rootStandardProjectsSorted,
    rootDraftProjectsSorted,
  } = React.useMemo(() => {
    if (!data?.workspace.projects || !data?.workspace.draftsProject) {
      return {
        projectsById: {},
        rootStandardProjectsSorted: [],
        rootDraftProjectsSorted: [],
      }
    }

    const standardProjects = data.workspace.projects.entries
    const draftProjects = data.workspace.draftsProject.entries

    const [rootStandardProjectsSorted, standardProjectsById] = sortProjects(
      standardProjects,
      workspaceId
    )

    const [rootDraftProjectsSortedRaw, draftProjectsByIdRaw] = sortProjects(
      draftProjects,
      workspaceId
    )

    // Special treatment for drafts (see parseDrafts for a detailed explanation)
    const [rootDraftProjectsSorted, draftProjectsById] = parseDrafts(
      workspaceId,
      rootDraftProjectsSortedRaw,
      draftProjectsByIdRaw
    )

    const projectsById = { ...standardProjectsById, ...draftProjectsById }

    return { projectsById, rootStandardProjectsSorted, rootDraftProjectsSorted }
  }, [data, workspaceId])

  return {
    ...query,
    ...normalizeProjects(data),
    projectsById,
    rootStandardProjectsSorted,
    rootDraftProjectsSorted,
  }
}

export const useGetProjectsLazy = () => {
  const [getProjects, workspaceResult] = useGetProjectsLazyQuery()
  const { data, ...rest } = workspaceResult

  return { getProjects, ...rest, ...normalizeProjects(data) }
}

function normalizeProjects(data: GetProjectsQuery | undefined) {
  if (!data) return {}

  const projects = data.workspace.projects
  const draftsProject = data.workspace.draftsProject

  return {
    projects,
    draftsProject,
    allProjects: [...draftsProject.entries, ...projects.entries],
  }
}

// Takes the projects data and normalizes it to a structure that can be used by
// different components, returning a list of sorted projects and an object of projects by id
function sortProjects(
  entries: ProjectInSidebarAndHeaderFragment[],
  workspaceId: string
): [ProjectLink[], ProjectsById] {
  // Create needed structure from project data, and fill the links
  const projectsLink: ProjectLink[] = entries.map(project => ({
    project,
    nestedProjects: [],
    link: routes.WORKSPACE_PROJECT.create({
      projectId: project.identifier,
      workspaceId,
    }),
  }))

  // Create a plain projects list by id, it's easier to manipulate
  const projectsById = projectsLink.reduce(
    (acc: { [key: string]: ProjectLink }, projectLink) => {
      acc[projectLink.project.identifier] = projectLink
      return acc
    },
    {}
  )

  // Fill the nested projects
  projectsLink.forEach(projectLink => {
    const parentId = projectLink.project.parentProjectIdentifier

    if (parentId && projectsById[parentId]) {
      projectsById[parentId].nestedProjects.push(projectLink)
    }
  })

  // Return only root projects and sorted
  const rootProjectsSorted = Object.values(projectsById)
    .filter(project => !project.project.parentProjectIdentifier)
    .sort((a, b) => {
      // If a is pinned and b is not, sort a to the start
      if (a.project.pinnedByCurrentUserAt && !b.project.pinnedByCurrentUserAt) {
        return -1
      }

      // If b is pinned and a is not, sort b to the start
      if (b.project.pinnedByCurrentUserAt && !a.project.pinnedByCurrentUserAt) {
        return 1
      }

      // If both a and b are pinned or not pinned, sort by name
      return a.project.name.localeCompare(b.project.name, undefined, {
        numeric: true,
      })
    })

  return [rootProjectsSorted, projectsById]
}

// My drafts project is a bit special, because it should be accessed via
// `.../drafts` url, and not with the project identifier like any other
// project, that's why we need to tweak these drafts lists
function parseDrafts(
  workspaceId: string,
  sorted: ProjectLink[],
  byId: ProjectsById
): [ProjectLink[], ProjectsById] {
  const myDrafts = sorted[0]

  if (!myDrafts) {
    return [[], {}]
  }

  const myDraftsId = myDrafts.project.identifier

  const rootDraftProjectsSorted: ProjectLink[] = [
    {
      ...myDrafts,
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  ]
  const draftProjectsById: ProjectsById = {
    ...byId,
    [myDraftsId]: {
      ...byId[myDraftsId],
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  }

  return [rootDraftProjectsSorted, draftProjectsById]
}

export function useGetRootProject(workspaceId: string, projectId: string) {
  const { projectsById } = useGetProjects({ workspaceId })

  let currentProject = projectsById[projectId]

  while (currentProject?.project?.parentProjectIdentifier) {
    currentProject =
      projectsById[currentProject.project.parentProjectIdentifier]
  }

  return currentProject
}
