import React from 'react'
import { useParams } from 'react-router-dom'
import { OperationVariables } from 'apollo-client'
import get from 'lodash.get'

import { DocumentHead } from '@sketch/components'
import { ResponsiveValues } from '@sketch/global-styles'
import { useVersioning } from '../../../versioning'
import PrototypesEmptyState from '../PrototypesEmptyState'
import { PrototypeContextProvider } from '../../../prototype/hooks'
import { PrototypeRendererProvider } from '@sketch-hq/sketch-web-renderer'

import {
  RouteParams,
  RetryQueryProps,
  useQueryRetry,
  useFlag,
} from '@sketch/modules-common'

// Graphql
import {
  FrameGroupDetailsFragment,
  GetArtboardsForDocumentDocument,
  GetArtboardsForDocumentQuery,
  GetFramesForDocumentDocument,
  GetFramesForDocumentQuery,
  useQuery,
} from '@sketch/gql-types'
import { FrameGroupsList } from '../FrameGroupsList'

type RetryIfBuilder = (
  path: string[]
) => RetryQueryProps<any, OperationVariables>['retryIf']

const buildRetryIf: RetryIfBuilder = path => ({ data, error }) => {
  if (error) return true

  const frames = get(data, path, []) as FrameGroupDetailsFragment[]
  const files = frames.map(a => a.files).flat()

  return files.some(file => !file?.url)
}

interface DocumentPrototypesProps {
  search: string
  columns: ResponsiveValues<number>
}

const entriesPath = (isFramesWebOn: boolean) => [
  'share',
  'version',
  'document',
  isFramesWebOn ? 'frames' : 'artboards',
  'entries',
]
const afterPath = (isFramesWebOn: boolean) => [
  'share',
  'version',
  'document',
  isFramesWebOn ? 'frames' : 'artboards',
  'meta',
  'after',
]

/**
 * Handles a list of frames scoped to a specific share document
 * grouped by page.
 */
export const DocumentPrototypes = ({
  columns,
  search,
}: DocumentPrototypesProps) => {
  const isFramesWebOn = useFlag('frames-web')

  const { versionShortId, share } = useVersioning()
  const { shareID } = useParams<RouteParams<'SHARE_VIEW'>>()

  const framesQuery = useQuery<
    GetFramesForDocumentQuery | GetArtboardsForDocumentQuery
  >(
    isFramesWebOn
      ? GetFramesForDocumentDocument
      : GetArtboardsForDocumentDocument,
    {
      variables: {
        identifier: shareID,
        versionShortId,
        search,
        /**
         * Note: The `PROTOTYPE` filter value filters for Frames that are Prototype start points.
         */
        filter: 'PROTOTYPE',
      },
    }
  )

  const response = useQueryRetry(framesQuery, {
    retryIf: buildRetryIf(entriesPath(isFramesWebOn)),
  })

  const userIsEditor = response.data?.share.userCanEdit
  const hasPrototypes =
    response.data?.share.version.document.artboards.meta.totalCount > 0

  if (!response.loading && !hasPrototypes) {
    return <PrototypesEmptyState userIsEditor={userIsEditor} />
  }

  return (
    <PrototypeRendererProvider>
      <DocumentHead title={`${share.name} Prototypes`} />
      <PrototypeContextProvider>
        <FrameGroupsList
          error={response.error}
          data={response.data}
          filterValue={search}
          loading={response.loading}
          fetchMore={response.fetchMore}
          columns={columns}
          afterPath={afterPath(isFramesWebOn)}
          entriesPath={entriesPath(isFramesWebOn)}
          enablePrototypeCards
        />
      </PrototypeContextProvider>
    </PrototypeRendererProvider>
  )
}
