import React from 'react'

import { Dropdown, useModalContext } from '@sketch/components'
import { useFlag } from '@sketch/modules-common'
import {
  ProjectInSidebarAndHeaderFragment,
  ProjectListItemFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'

import {
  CopyProjectAction,
  CreateCollectionAction,
  CreateNestedProjectAction,
  DeleteProjectAction,
  LeaveProjectAction,
  EditProjectAction,
  RenameProjectAction,
  ShareProjectAction,
  PinProjectAction,
} from '../ProjectDropdownActions'

import ArchiveProjectModal from '../../modals/ArchiveProjectModal'
import UnarchiveProjectModal from '../../modals/UnarchiveProjectModal'
import { userIsEditorOrAdmin } from '../../../workspace/utils'

interface ProjectDropdownProps {
  workspace: WorkspaceMinimalFragment
  project: ProjectInSidebarAndHeaderFragment | ProjectListItemFragment
  onRename?: () => void
}

const ProjectDropdown = (props: ProjectDropdownProps) => {
  const { workspace, project, onRename } = props
  const workspaceId = workspace.identifier

  const { showModal } = useModalContext()

  const isNestedProjectsOn = useFlag('nested-projects')

  const pinned = project.pinnedByCurrentUserAt
  const isArchived = !!project.archivedAt

  // only editors and admins can perform the following actions:
  //   - Add/remove people from the project (<ShareProjectAction .../>)
  //   - Rename the project (<RenameProjectAction .../>)
  //   - Delete the project (<DeleteProjectAction .../>)
  const showEditActions = userIsEditorOrAdmin(workspace)
  const showShareAction = showEditActions && project.type === 'STANDARD'
  const showLeaveAction = project.userCanLeave
  const showDivider = showEditActions || showLeaveAction
  const showArchive = showEditActions && workspace.features.archiveEnabled
  const showPinActions = project.type === 'STANDARD' && !isArchived

  if (project.archivedAt) {
    return (
      <>
        <Dropdown.Header>Project</Dropdown.Header>
        <CopyProjectAction project={project} workspaceId={workspaceId} />
        {showDivider && <Dropdown.Divider />}
        {showEditActions && isNestedProjectsOn && (
          <EditProjectAction workspaceId={workspaceId} project={project} />
        )}
        {showEditActions && onRename && (
          <RenameProjectAction onRename={onRename} />
        )}
        {showDivider && <Dropdown.Divider />}
        {showLeaveAction && (
          <LeaveProjectAction
            projectIdentifier={project.identifier}
            projectName={project.name}
          />
        )}
        {showEditActions && (
          <Dropdown.Item
            onClick={() => {
              showModal(UnarchiveProjectModal, {
                projectIdentifier: project.identifier,
                projectName: project.name,
                workspaceIdentifier: workspaceId,
              })
            }}
          >
            Unarchive&hellip;
          </Dropdown.Item>
        )}
        {showEditActions && (
          <DeleteProjectAction project={project} workspaceId={workspaceId} />
        )}
      </>
    )
  }

  return (
    <>
      <Dropdown.Header>Project</Dropdown.Header>
      {showShareAction && (
        <ShareProjectAction project={project} workspace={workspace} />
      )}
      <CopyProjectAction project={project} workspaceId={workspaceId} />
      {showDivider && <Dropdown.Divider />}
      {showEditActions && isNestedProjectsOn && (
        <EditProjectAction workspaceId={workspaceId} project={project} />
      )}
      {showEditActions && onRename && (
        <RenameProjectAction onRename={onRename} />
      )}
      {showPinActions && (
        <PinProjectAction
          projectIdentifier={project.identifier}
          pinned={pinned}
          workspaceId={workspaceId}
        />
      )}
      {showEditActions && (
        <>
          {isNestedProjectsOn ? (
            <CreateNestedProjectAction
              project={project}
              workspaceIdentifier={workspaceId}
            />
          ) : (
            <CreateCollectionAction
              project={project}
              workspaceIdentifier={workspaceId}
            />
          )}
        </>
      )}
      {showDivider && <Dropdown.Divider />}
      {showArchive && (
        <Dropdown.Item
          onClick={() => {
            showModal(ArchiveProjectModal, {
              project,
              workspaceIdentifier: workspaceId,
            })
          }}
        >
          Archive&hellip;
        </Dropdown.Item>
      )}
      {showLeaveAction && (
        <LeaveProjectAction
          projectIdentifier={project.identifier}
          projectName={project.name}
        />
      )}
      {showEditActions && (
        <DeleteProjectAction project={project} workspaceId={workspaceId} />
      )}
    </>
  )
}

export default ProjectDropdown
