import { ICryptoWorkerClient } from './client'

export type Env = {
  keyId: string
  client: ICryptoWorkerClient
}

export type PathMut = string | PathMutFn

export type PathMutFn = (context: any, next: NextFn, env: Env) => Promise<void>

export type NextFn = (context: any) => Promise<void>

export const xpath = async <T>(
  context: T,
  path: PathMut[],
  env: Env
): Promise<T> => {
  await xpath_(context, path, env)
  return context
}

const xpath_ = async (
  context: any,
  path: PathMut[],
  env: Env
): Promise<void> => {
  if (path.length === 0) {
    return Promise.reject(new Error('invalid path'))
  }
  const item = path[0]
  const next: NextFn = context => xpath(context, path.slice(1), env)
  if (typeof item === 'string') {
    const f = key(item) as PathMutFn
    await f(context, next, env)
  } else if (typeof item === 'function') {
    await item(context, next, env)
  }
}

export const all = (): PathMut => {
  return async (context: any[], next: NextFn, env: Env) => {
    if (context instanceof Array) {
      await Promise.all(context.map(next))
    } else {
      return Promise.reject(new Error('invalid path'))
    }
  }
}

export const key = (key: string): PathMut => {
  return async (context: any, next: NextFn, env: Env) => {
    if (key in context) {
      return next(context[key])
    } else {
      return Promise.reject(new Error('invalid path'))
    }
  }
}

export const encrypt = (key: string): PathMut => {
  return async (context: any, next: NextFn, env: Env) => {
    if (key in context) {
      await env.client
        .encryptText(env.keyId, context[key])
        .then(value => {
          context[key] = value
        })
        .catch(() => Promise.reject(new Error('encryption error')))
    } else {
      return Promise.reject(new Error('invalid path'))
    }
  }
}

export const decrypt = (encKey: string, key: string): PathMut => {
  return async (context: any, next: NextFn, env: Env) => {
    if (encKey in context && key in context) {
      const encValue = context[encKey]
      if (encValue.__typename === 'EncText') {
        await env.client
          .decryptText(env.keyId, encValue.contents)
          .then(value => {
            context[key] = value
          })
          .catch(() => Promise.reject(new Error('decryption error')))
      }
    } else {
      return Promise.reject(new Error('invalid path'))
    }
  }
}
