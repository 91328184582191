import { useFlag } from '../flags'
import {
  CryptoWorkerClient,
  ICryptoWorkerClient,
  NullClient,
  getAwsConfig,
} from './client'
import { useEffect } from 'react'

declare module '@sketch/utils' {
  export interface EventsMap {
    storage: void
  }
}

let globalCryptoWorkerClient: ICryptoWorkerClient = new NullClient()

/**
 * Initialises the web worker client for workspaces with the encryption feature
 * configured.
 *
 * IMPORTANT: This does not initialise the web worker.
 *
 * This effect should be used only once per app, and created as early as
 * possible and remain in context all the time. Until then, it provides a
 * `NullClient`, which contains the same functions available in the regular
 * client, but they all return an error.
 *
 * To use the web worker, you should use the `getCryptoWorkerClient` function,
 * which returns an object capable of communicating with the web worker.
 */
export const useCryptoWorker = () => {
  const encryptionEnabled = useFlag('encryption')

  useEffect(() => {
    const nullClient = globalCryptoWorkerClient instanceof NullClient

    const configCryptoWorker = async () => {
      return globalCryptoWorkerClient.configure(getAwsConfig())
    }

    if (nullClient && encryptionEnabled) {
      globalCryptoWorkerClient = new CryptoWorkerClient()
      window.addEventListener('storage', configCryptoWorker)
      configCryptoWorker()

      return () => {
        window.removeEventListener('storage', configCryptoWorker)
        globalCryptoWorkerClient.terminate()
        globalCryptoWorkerClient = new NullClient()
      }
    }
  }, [encryptionEnabled])
}

export const getCryptoWorkerClient = (): ICryptoWorkerClient => {
  return globalCryptoWorkerClient
}

export const CryptoWorker = () => {
  useCryptoWorker()
  return null
}
