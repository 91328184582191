import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { IS_EMBEDDED } from '@sketch/constants'
import { useForTablet } from '@sketch/components'
import {
  isCwvRouteOnly,
  isArtboardRoute,
  isFrameGroupRoute,
} from '@sketch/modules-common'
import { useSharesTracking } from '../SharesTrackingContext'

import {
  INSPECTOR_SEGMENT,
  INSPECTOR_URL_HASH,
  SegmentValues,
} from '../../constants'

type StateSegmentValues = SegmentValues | undefined

type ShareSidebarTabContextTypes = [
  StateSegmentValues,
  (value: StateSegmentValues) => void
]

type MobileSidebar = {
  isSidebarRightOpen: boolean
  toggleSidebarRight: () => void
}

const ShareSidebarTabContext = React.createContext([
  undefined,
  () => {},
] as ShareSidebarTabContextTypes)

/**
 * ShareActiveSidebarTab
 *
 * This component will save the active tab when changing views
 * in case a given tab has been selected prior.
 *
 * We kept compatibility with the #Inspector url to set the
 * tab active when passed
 */
const ShareActiveSidebarTab: React.FC = ({ children }) => {
  const state = useState<StateSegmentValues>(undefined)

  return (
    <ShareSidebarTabContext.Provider value={state}>
      {children}
    </ShareSidebarTabContext.Provider>
  )
}

export const isAllowedSegment = (
  value: string,
  segments: SegmentValues[]
): value is SegmentValues => (segments as string[]).includes(value)

interface LocationState {
  toggleFloatingPanel?: boolean
  toggleSidebarRight?: boolean
}

/**
 * useShareSidebarTab
 *
 * This contains the logic of segments that will be used in the floating panels
 * implementations (or sidebar for mobile)
 */
export const useShareSidebarTab = (
  segments: SegmentValues[],
  mobileSidebar?: MobileSidebar
) => {
  const [activeTab, setActiveTab] = useContext(ShareSidebarTabContext)
  const location = useLocation<LocationState>()
  const history = useHistory()
  const { path } = useRouteMatch()

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const currentHash = location.hash.slice(1)
  const allowedSegment = isAllowedSegment(currentHash, segments)
    ? currentHash
    : undefined

  const allowedTab = isAllowedSegment(activeTab || '', segments)
    ? activeTab
    : undefined

  const { isShareChanged } = useSharesTracking()

  // In general we want to show a default panel, except when the user actively
  // toggles a panel or for some other views like CWV or Artboards
  const canShowDefaultPanel =
    isShareChanged &&
    !IS_EMBEDDED &&
    !location.state?.toggleFloatingPanel &&
    !isCwvRouteOnly(path) &&
    !isArtboardRoute(path) &&
    !isFrameGroupRoute(path)

  /**
   * If the user doesn't actively click on one tab/icon we don't set any value
   * on purpose because that could force the user to loose other views.
   *
   * This doesn't apply to mobile views.
   *
   * `location.state.toggleFloatingPanel` is true when the user actively changed
   * a tab
   */
  const preventiveActiveTab = allowedTab
    ? allowedTab
    : canShowDefaultPanel
    ? segments[0]
    : undefined

  const toggleSegment = (hash: SegmentValues) => {
    const newHash = isMobile
      ? activeTab === hash
        ? undefined
        : hash
      : preventiveActiveTab === hash
      ? undefined
      : hash

    history.push({
      ...history.location,
      hash: newHash,
      state: {
        ...location.state,
        toggleFloatingPanel: true,
        toggleSidebarRight: false,
      },
    })
    setActiveTab(newHash)
  }

  /**
   * Url is primary source of truth for active tab
   * Sync active tab with url
   */
  useEffect(() => {
    setActiveTab(allowedSegment)
  }, [allowedSegment, setActiveTab])

  /**
   * If we dont have a url, active tab is secondary source of truth
   * Sync url with active tab if no hash present
   */
  useEffect(() => {
    if (
      !location.hash &&
      allowedTab &&
      !location.state?.toggleSidebarRight &&
      !location.state?.toggleFloatingPanel
    ) {
      history.replace({
        ...location,
        hash: allowedTab,
      })
    }
  }, [location, allowedTab, history])

  /**
   * Sync active segment with sidebar right state (only for mobile)
   */
  useEffect(() => {
    const { isSidebarRightOpen, toggleSidebarRight } = mobileSidebar || {}

    if (!isMobile || typeof isSidebarRightOpen !== 'boolean') {
      return
    }

    // Remove URL hash when sidebar is closed
    if (
      allowedSegment &&
      location.state?.toggleSidebarRight &&
      !isSidebarRightOpen
    ) {
      history.replace({
        ...location,
        hash: undefined,
      })

      return
    }

    // Sync sidebar with current segment
    if (!!allowedSegment !== isSidebarRightOpen) {
      toggleSidebarRight?.()
    }
  }, [
    allowedSegment,
    history,
    isMobile,
    location,
    mobileSidebar,
    preventiveActiveTab,
    segments,
  ])

  /**
   * We kept compatibility with the #Inspector url to set the
   * tab active when passed
   */
  useEffect(() => {
    if (location.hash.includes(INSPECTOR_URL_HASH)) {
      history.replace({
        ...location,
        hash: INSPECTOR_SEGMENT,
      })
    }
  }, [location, history])

  return {
    activeSegment: preventiveActiveTab,
    setActiveSegment: setActiveTab,
    toggleSegment,
  } as const
}

/**
 * useGetActiveSidebarTab
 *
 * Just serves the active tab
 */
export const useGetActiveSidebarTab = () =>
  useContext(ShareSidebarTabContext)[0]

/**
 * Exposes just the active tab and toggle functionality
 */
export const useToggleSidebarTab = () => {
  const history = useHistory()
  const location = useLocation<LocationState>()

  const [activeTab, setActiveTab] = useContext(ShareSidebarTabContext)

  const toggleTab = (hash?: SegmentValues) => {
    history.push({
      ...location,
      hash: activeTab === hash ? undefined : hash,
      state: {
        ...location.state,
        toggleFloatingPanel: true,
        toggleSidebarRight: false,
      },
    })

    setActiveTab(activeTab === hash ? undefined : hash)
  }

  return {
    active: activeTab,
    toggle: toggleTab,
    isDefault: !location.state?.toggleFloatingPanel,
  }
}

export default ShareActiveSidebarTab
