import { useFlag } from '../flags'
import { ICryptoWorkerClient } from './client'
import { getCryptoWorkerClient } from './useCryptoWorker'

export interface EncryptionContext {
  encryption?: {
    keyId: string
    client: ICryptoWorkerClient
  }
}

export const useCreateEncryption = (workspaceId: string): EncryptionContext => {
  const encEnabled = useFlag('encryption')
  if (!encEnabled) {
    return {}
  }
  return {
    encryption: {
      keyId: workspaceId,
      client: getCryptoWorkerClient(),
    },
  }
}
