import React, { useMemo } from 'react'

import { Flex, TruncateWithTooltip } from '@sketch/components'
import { Chevron } from '../TextOrLayerStyles'

import { getFrameThumbnail } from '../../../../../utils/getFrameThumbnail'

import { Header, HeaderTitle, Section, Separator } from '../../components'
import { ArtboardDetailInspectorSymbolLink } from '../../../ArtboardDetailInspectorSymbolLink'
import { useSymbolMasterDocument } from '../../../../hooks/inspector'
import {
  SymbolMaster,
  ElementType,
  SketchLayerElement,
} from '../../../../../../inspector'
import { useComponentsState } from '../../../../../components/ComponentsStateContext'
import { StyledSkeleton } from '../../ArtboardDetailInspector.styles'

import {
  SymbolBox,
  SymbolContainer,
  SymbolPath,
  SymbolThumbnail,
  SymbolTruncateTooltip,
} from './SymbolInSidebar.styles'

interface SymbolProps {
  layerElement: SketchLayerElement
}

export const SymbolInSidebar: React.FC<SymbolProps> = ({ layerElement }) => {
  const componentsState = useComponentsState()

  const symbolLayerElement =
    layerElement.type === ElementType.SymbolInstance
      ? layerElement
      : layerElement.parentSymbolLayerElement!

  const { displayedName, symbolPath } = useDisplayedLayerNameAndOptionalPath(
    symbolLayerElement.symbolMaster
  )

  const { frame, shareIdentifier } = useSymbolMasterDocument(
    symbolLayerElement.symbolMaster
  )

  const symbolThumbnail = frame ? getFrameThumbnail(frame, 'S') : null

  // When the user does not have access to a document where a symbol exists
  // We expect symbolMasterPermanentArtboardShortId to be undefined.
  const isSymbolLink = Boolean(frame)

  const symbolDetail = (
    <SymbolBox isClickable={isSymbolLink}>
      {symbolThumbnail && (
        <Flex width="32px" marginRight={2}>
          <SymbolThumbnail src={symbolThumbnail} alt="thumbnail" />
        </Flex>
      )}
      <SymbolContainer
        marginRight={2}
        data-testid="inspector-sidebar-symbol-info"
      >
        <TruncateWithTooltip>{displayedName}</TruncateWithTooltip>
        <SymbolPath data-testid="inspector-sidebar-symbol-path">
          <TruncateWithTooltip placement="bottom-start">
            <SymbolTruncateTooltip>{symbolPath}</SymbolTruncateTooltip>
          </TruncateWithTooltip>
        </SymbolPath>
      </SymbolContainer>
      {isSymbolLink ? <Chevron /> : null}
    </SymbolBox>
  )

  return (
    <>
      <Separator />
      <Section data-testid="inspector-sidebar-symbol">
        <Header>
          <HeaderTitle>Symbol</HeaderTitle>
        </Header>
        {componentsState === 'PROCESSING' ? (
          <StyledSkeleton />
        ) : (
          <ArtboardDetailInspectorSymbolLink
            frame={frame}
            shareIdentifier={shareIdentifier}
            symbolMaster={symbolLayerElement.symbolMaster}
          >
            {symbolDetail}
          </ArtboardDetailInspectorSymbolLink>
        )}
      </Section>
    </>
  )
}

/**
 * The name displayed in the overview is sometimes more than just the layer name.
 * Symbols can be named in Sketch with Slashes in the name.
 * E.g. `Primitive/Icon/Arrow/Right`. Slashes in symbol names act like groups,
 * and the name gets divided into symbolMasterNamePath and symbolMasterShortName
 * if that's the case.
 */
function useDisplayedLayerNameAndOptionalPath(
  symbolMaster: SymbolMaster
): { displayedName: string; symbolPath: string | undefined } {
  return useMemo(() => {
    const docName = symbolMaster.isForeign
      ? symbolMaster.documentName
      : 'This Document'
    if (
      symbolMaster.symbolMasterNamePath?.length &&
      symbolMaster.symbolMasterShortName
    ) {
      const symbolNamePathWithDocument = `${docName}/${symbolMaster.symbolMasterNamePath.join(
        '/'
      )}`
      return {
        displayedName: symbolMaster.symbolMasterShortName,
        symbolPath: symbolNamePathWithDocument,
      }
    }

    return {
      displayedName: symbolMaster.name,
      symbolPath: docName,
    }
  }, [symbolMaster])
}
