import React, { useMemo } from 'react'

import { CanvasRendererProvider } from '@sketch-hq/sketch-web-renderer'
import {
  ArtboardDetailInfoFragment,
  GetDetailViewArtboardQuery,
  useGetDetailViewArtboardQuery,
  VersionFragment,
} from '@sketch/gql-types'
import {
  RouteParams,
  useFlag,
  useQueryRetry,
  versionedRoutes,
} from '@sketch/modules-common'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import {
  RevisionChangedProps,
  UseRevisionSubscriptionsProps,
} from '../../../../activity/operations'
import { AnnotationQueryVariablesProvider } from '../../../../annotations/context'
import { DocumentSidebarLayoutExtraProps } from '../../../components/DocumentSidebarLayout'
import { RevisionSubscriptions } from '../../../components/RevisionSubscriptions'
import { Pagination } from '../../../types'
import {
  isUpgradeToLatestNeeded,
  UpgradeToLatestVersion,
  useVersioning,
} from '../../../../versioning'

import { useOnCurrentRevisionChange } from '../../hooks'
import { ArtboardDetail } from '../ArtboardDetail'
import { ArtboardAnnotationsOverlayContext } from '../ArtboardAnnotationsOverlayContext'
import { FrameGroupInspectorProvider } from '../FrameGroupInspectorProvider'
import { FrameGroupContextProvider } from '../../hooks/useFrameGroupContext'
import { FrameGroupDetailErrorBoundary } from '../FrameGroupDetailErrorBoundary'
import { INSPECTOR_SEGMENT } from '../../../constants'

const getArtboard = (result: { data?: GetDetailViewArtboardQuery }) => {
  const artboard = result?.data?.artboard
  // at some point Apollo (while loading) returns an artboard which contains only
  // a symbol of id, all other properties are non existing
  // this gives a false impression, that the object is there, but it isn't
  return artboard?.identifier ? artboard : undefined
}

type MaybeArtboard = ReturnType<typeof getArtboard>

interface UseRetryGetDetailViewArtboardQueryProps {
  permanentArtboardShortId: string
  versionShortId?: string
  shareIdentifier: string
  enabled: boolean
}

const useRetryGetDetailViewArtboardQuery = ({
  permanentArtboardShortId,
  versionShortId,
  shareIdentifier,
  enabled = true,
}: UseRetryGetDetailViewArtboardQueryProps) =>
  useQueryRetry(
    useGetDetailViewArtboardQuery({
      shouldInvalidatePrevious: (prev, curr) =>
        prev?.permanentArtboardShortId !== curr?.permanentArtboardShortId,
      // To account for artboard updates we're setting a cache-and-network
      // policy for artboard queries without an explicit version.
      fetchPolicy: versionShortId ? 'cache-first' : 'cache-and-network',
      variables: {
        permanentArtboardShortId: permanentArtboardShortId,
        documentVersionShortId: versionShortId,
        shareIdentifier,
      },
      skip: !enabled,
    }),
    {
      retryIf: result => {
        if (!enabled) return false
        const artboard = getArtboard(result)
        const files = (artboard && artboard.files) || []
        return files.some(file => !file?.url)
      },
    }
  )

const getPagination = (
  version: VersionFragment | undefined,
  artboard: ArtboardDetailInfoFragment | undefined
): Pagination | undefined => {
  if (!version || !artboard) return undefined
  const total = version.document?.artboardCount
  const current =
    artboard.documentOrder == null ? undefined : artboard.documentOrder

  return { current, total }
}

const getArtboardForSubscription = (
  maybeArtboard: MaybeArtboard
): UseRevisionSubscriptionsProps['artboard'] | null => {
  if (!maybeArtboard?.identifier || !maybeArtboard?.permanentArtboardShortId)
    return null

  return {
    identifier: maybeArtboard.identifier,
    permanentArtboardShortId: maybeArtboard.permanentArtboardShortId,
    uuid: maybeArtboard.uuid,
  }
}

type ArtboardDetailViewRouteProps = RouteComponentProps<
  Partial<RouteParams<'ARTBOARD_REVISION'>> & RouteParams<'ARTBOARD_DETAIL'>
>

export interface ArtboardDetailQueriesProps
  extends ArtboardDetailViewRouteProps,
    DocumentSidebarLayoutExtraProps {}

export const ArtboardDetailQueries = ({
  match,
  history,
  location,
  ...layoutProps
}: ArtboardDetailQueriesProps) => {
  const { permanentArtboardShortId, revisionDocShortId } = match.params

  const isInspectPanelOpen = location.hash.includes(INSPECTOR_SEGMENT)

  const versionContext = useVersioning()
  const isFrameWebOn = useFlag('frames-web')

  const { onCurrentRevisionChange } = useOnCurrentRevisionChange()
  const { versionShortId, share, isViewingLatestVersion } = versionContext

  const artboardAtDocResult = useRetryGetDetailViewArtboardQuery({
    permanentArtboardShortId,
    versionShortId,
    shareIdentifier: share.identifier,
    enabled: true,
  })

  const artboardAtRevResult = useRetryGetDetailViewArtboardQuery({
    permanentArtboardShortId,
    versionShortId: revisionDocShortId || versionShortId,
    shareIdentifier: share.identifier,
    enabled: true,
  })

  const artboardAtLatestResult = useRetryGetDetailViewArtboardQuery({
    permanentArtboardShortId,
    /*
     * passing latestVersionId does not work
     * as on the latest version of the document the artboard might be deleted
     * leaving this comment here, so that if anyone would
     * try to optimise it in the future, they would be aware
     * more: https://github.com/sketch-hq/cloud-frontend/pull/800#discussion_r289356950
     */
    // versionId={latestVersionId}
    shareIdentifier: share.identifier,
    enabled: true,
  })

  const error =
    artboardAtDocResult.error ||
    artboardAtRevResult.error ||
    artboardAtLatestResult.error

  const loading =
    artboardAtDocResult.loading ||
    artboardAtRevResult.loading ||
    artboardAtLatestResult.loading

  const artboards = {
    docVersion: getArtboard(artboardAtDocResult),
    revVersion: getArtboard(artboardAtRevResult),
    latest: getArtboard(artboardAtLatestResult),
  }

  const subjects = useMemo(() => {
    if (artboards.revVersion) {
      return [
        {
          type: 'ARTBOARD' as const,
          permanentId: artboards?.revVersion?.uuid || '',
          permanentPageId: artboards?.revVersion?.page?.uuid || '',
        },
      ]
    }

    return []
  }, [artboards.revVersion])

  /**
   * This code allows old URLS that are on the artboard view to be redirected to
   * the newer frame view.
   *
   * Once "frame-web" is released, the "ArtboardDetailQueries" will be a redirect only
   * to the newer route.
   *
   * https://linear.app/sketch/issue/SWEB-520/replace-the-artboard-view-by-a-redirect-to-the-frame-view
   */
  if (isFrameWebOn && artboards.latest && artboards.docVersion) {
    const isLatestVersion =
      artboards.latest.documentVersionShortId ===
      artboards.docVersion.documentVersionShortId

    const redirectURL = isLatestVersion
      ? versionedRoutes.FRAME.LATEST.create({
          shareID: share.identifier,
          frameUUID: artboards.latest.uuid,
        })
      : versionedRoutes.FRAME.VERSION.create({
          shareID: share.identifier,
          frameUUID: artboards.latest.uuid,
          versionShortId: artboards.docVersion.documentVersionShortId,
        })

    return <Redirect to={redirectURL} />
  }

  if (isUpgradeToLatestNeeded(error)) {
    artboardAtLatestResult.refetch()
    return <UpgradeToLatestVersion error={error} />
  }

  // if there is no latest revision or artboard passed,
  // we are assuming that we are looking at the latest version
  const isViewingLatestRevision =
    !artboards.latest ||
    !artboards.revVersion ||
    artboards.latest.revisionIdentifier ===
      artboards.revVersion.revisionIdentifier

  const { currentVersion } = (!versionContext.loading && versionContext) || {}

  const pagination = getPagination(currentVersion, artboards.docVersion)

  const onRevisionChange = (data: RevisionChangedProps) => {
    /**
     * In all cases when revision changes (moved, deleted, created)
     * it is possible to find a case that latest revision also
     * has changed. Therefore it is a good idea, always refetch
     * the latest revision.
     *
     * Also see https://github.com/sketch-hq/Cloud/issues/1392
     */
    artboardAtLatestResult.refetch()
    if (data.selectedRevisionChange) {
      onCurrentRevisionChange(data)
    }
  }

  const subscriptionArtboard = getArtboardForSubscription(artboards.revVersion)

  const disableAnnotations = !artboards.docVersion || !share.commentsEnabled

  return (
    <FrameGroupDetailErrorBoundary>
      <CanvasRendererProvider>
        <FrameGroupInspectorProvider
          //  Reset context for each artboard
          key={artboards.revVersion?.identifier}
        >
          <AnnotationQueryVariablesProvider
            shareIdentifier={share.identifier}
            subjects={subjects}
          >
            <ArtboardAnnotationsOverlayContext
              disabled={disableAnnotations}
              hidden={isInspectPanelOpen}
              permanentPageId={artboards?.revVersion?.page?.uuid || ''}
              isViewingLatestVersion={
                isViewingLatestRevision && isViewingLatestVersion
              }
            >
              <FrameGroupContextProvider>
                <ArtboardDetail
                  share={share}
                  currentVersion={currentVersion}
                  artboard={artboards.revVersion}
                  isViewingLatestVersion={isViewingLatestRevision}
                  isPrototypePlayEnabled={isViewingLatestRevision}
                  artboardAtLatestVersion={artboards.latest}
                  pagination={pagination}
                  error={error}
                  loading={loading}
                  layoutProps={layoutProps}
                />
                {subscriptionArtboard && (
                  <RevisionSubscriptions
                    artboard={subscriptionArtboard}
                    onRevisionChange={onRevisionChange}
                    share={share}
                  />
                )}
              </FrameGroupContextProvider>
            </ArtboardAnnotationsOverlayContext>
          </AnnotationQueryVariablesProvider>
        </FrameGroupInspectorProvider>
      </CanvasRendererProvider>
    </FrameGroupDetailErrorBoundary>
  )
}
