import React, { useRef } from 'react'

import {
  usePRFileArtboards,
  PRMarinaFileArtboard,
  useRootNodeAbsoluteExtent,
  Rect,
} from '@sketch-hq/sketch-web-renderer'

import { ArtboardOverlay } from '../ArtboardOverlay'
import { useArtboardEvents, useArtboardSelected } from '../../hooks'

// TODO: Rename this component to be consistent with FrameGroup
// https://linear.app/sketch/issue/SWEB-500/rename-common-components-between-artboards-and-frames-to

interface ArtboardsInfoOverlayProps {
  disableSelection?: boolean
}

interface RepresentativeArtboardsInfoOverlayProps {
  artboardEvents?: ReturnType<typeof useArtboardEvents>
  artboardsRefs?: React.RefObject<Record<string, HTMLElement | null>>
  disablePointerCursor?: boolean
  disableHover?: boolean
  selectedArtboardUUID?: string | null
}

interface MemoizedArtboardsInfoOverlayProps
  extends RepresentativeArtboardsInfoOverlayProps {
  artboards: PRMarinaFileArtboard[]
  rootExtent: Rect
}

/**
 * This is an overlay that renders the artboards' names,
 * cta, outline and tracks interactions on them.
 */
const MemoizedArtboardsInfoOverlay = React.memo(
  function MemoizedArtboardsInfoOverlay(
    props: MemoizedArtboardsInfoOverlayProps
  ) {
    const {
      artboards,
      rootExtent,
      disablePointerCursor,
      disableHover,
      selectedArtboardUUID,
      artboardEvents,
      artboardsRefs,
    } = props

    return (
      <>
        {artboards.map(artboard => (
          <ArtboardOverlay
            key={artboard.objectId}
            ref={ref => {
              if (!artboardsRefs) {
                return
              }

              artboardsRefs.current![artboard.objectId] = ref
            }}
            bounds={artboard.bounds}
            artboardName={artboard.name}
            artboardUUID={artboard.objectId}
            selected={selectedArtboardUUID === artboard.objectId}
            events={artboardEvents}
            disablePointerCursor={disablePointerCursor}
            disableHover={disableHover}
            rootExtent={rootExtent}
          />
        ))}
      </>
    )
  }
)

export const RepresentativeArtboardsInfoOverlay = (
  props: RepresentativeArtboardsInfoOverlayProps
) => {
  const {
    artboardEvents,
    artboardsRefs,
    disablePointerCursor,
    disableHover,
    selectedArtboardUUID,
  } = props

  const artboards = usePRFileArtboards() ?? []
  const rootExtent = useRootNodeAbsoluteExtent()

  return (
    <MemoizedArtboardsInfoOverlay
      artboards={artboards}
      artboardEvents={artboardEvents}
      artboardsRefs={artboardsRefs}
      disableHover={disableHover}
      disablePointerCursor={disablePointerCursor}
      selectedArtboardUUID={selectedArtboardUUID}
      rootExtent={rootExtent}
    />
  )
}

export const ArtboardsInfoOverlay = (props: ArtboardsInfoOverlayProps) => {
  const { disableSelection } = props
  const artboardsRefs = useRef<Record<string, HTMLDivElement | null>>({})

  const { artboardEvents, selectedArtboardUUID } = useArtboardSelected(
    artboardsRefs
  )

  return (
    <RepresentativeArtboardsInfoOverlay
      artboardEvents={disableSelection ? undefined : artboardEvents}
      artboardsRefs={artboardsRefs}
      disablePointerCursor={disableSelection}
      selectedArtboardUUID={selectedArtboardUUID}
    />
  )
}
